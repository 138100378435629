import React from "react";
import { Route, Switch } from "react-router-dom";
import Card from "./Card";
import Browser from "./Browser";
import PrivacyPolicy from "./PrivacyPolicy";
import { useScrollToTop } from "../hooks";
import Login from "./Login";
import DeckBuilder from "./DeckBuilder";

const Doomtown = () => {
  useScrollToTop();

  return (
    <div className="max-w-screen-xl mx-auto">
      <Switch>
        <Route path="/dt_login">
          <Login />
        </Route>

        <Route path="/decks/new">
          <DeckBuilder />
        </Route>

        <Route path="/cards/:imagePath">
          <Card />
        </Route>

        <Route path="/privacy">
          <PrivacyPolicy />
        </Route>

        <Route path="/">
          <Browser />
        </Route>
      </Switch>
    </div>
  );
};

export default Doomtown;
