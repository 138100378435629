import React, { CSSProperties, useEffect, useState } from "react";
import cl from "../lib/cloudinary";
import { Transformation } from "cloudinary-core";
import { Card, Dude, Outfit } from "../types/graphql-global-types";

const CardImage: React.FC<{
  className: string;
  style?: CSSProperties;
  loadingClassName?: string;
  loadedClassName?: string;
  card: Card;
  alt: string;
  options?: Transformation | Transformation.Options;
  wait?: number;
  onClick?: (card: Card) => void;
  onMouseOver?: (card: Card) => void;
  onMouseLeave?: (card: Card) => void;
}> = ({
  className,
  style,
  loadingClassName,
  loadedClassName,
  card,
  alt,
  options,
  wait,
  onClick,
  onMouseOver,
  onMouseLeave
}) => {
  options = options || { quality: "auto:eco" };
  wait = wait ?? 500;

  const [imageLoaded, setImageLoaded] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState(false);

  useEffect(() => {
    const cardImage = new Image();
    cardImage.src = cl.url(card.imagePath, options);

    cardImage.onload = () => {
      setImageLoaded(true);
    };

    return () => {
      cardImage.onload = null;
    };
  }, [card, options, setImageLoaded]);

  useEffect(() => {
    setTimeout(() => setTimeElapsed(true), wait);
  }, [setTimeElapsed, wait]);

  return imageLoaded && timeElapsed ? (
    <img
      className={`${className} ${loadedClassName}`}
      style={style}
      src={cl.url(card.imagePath, options)}
      alt={alt}
      onClick={() => onClick && onClick(card)}
      onMouseEnter={() => onMouseOver && onMouseOver(card)}
      onMouseLeave={() => onMouseLeave && onMouseLeave(card)}
    />
  ) : (
    <img
      className={`${className} ${loadingClassName}`}
      style={style}
      src={cl.url(getLoadingImageByCard(card), options)}
      alt="Loading"
    />
  );
};

const getLoadingImageByCard = (card: Card): string => {
  if ((card as Dude).faction || (card as Outfit).faction) {
    let factionId = (card as Dude).faction?.id;

    if (!factionId) {
      factionId = (card as Outfit).faction?.id;
    }

    switch (factionId) {
      case 1:
        return "pro_the_agency_back";
      case 2:
        return "pro_blackjacks_back";
      case 3:
        return "pro_collegium_back";
      case 4:
        return "pro_flock_back";
      case 5:
        return "pro_law_dogs_back";
      case 7:
        return "pro_the_maze_rats_back";
      case 8:
        return "pro_sioux_union_back";
      case 9:
        return "pro_sweetrock_back";
      case 10:
        return "pro_the_texas_rangers_back";
      case 11:
        return "pro_whateley_family_estate_back";
      default:
        return "reverse";
    }
  } else {
    return "reverse";
  }
};

export default CardImage;
