import React, { useState } from "react";
import cl from "../lib/cloudinary";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

const Header: React.FC = () => {
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

  return (
    <div className="relative">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center border-b-2 border-dark pt-2 pb-6 md:justify-start md:space-x-10">
          <div className="lg:w-0 lg:flex-1">
            <Link className="flex" to="/">
              <img
                className="md:h-20 w-auto h-12 xl:ml-8"
                src={cl.url("deadlands", { quality: "auto:eco" })}
                alt="Deadlands"
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <button
              onClick={() => setShowMobileMenu(true)}
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-dark focus:outline-none focus:bg-darker focus:text-gray-500 transition duration-150 ease-in-out"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <div className="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
            <Link
              to="/cards"
              className="whitespace-no-wrap text-base text-2xl font-semibold shadow-lg font-display text-gray-200 hover:underline"
            >
              Cards
            </Link>
            <span className="inline-flex rounded-md shadow-sm">
              <Link
                to="/decks/new"
                className="whitespace-no-wrap text-base w-full inline-flex items-center justify-center px-4 py-2 text-center rounded-lg bg-link text-gray-300 text-xl uppercase font-display font-extrabold opacity-75 hover:opacity-100 active:opacity-100"
              >
                New Deck
              </Link>
            </span>
          </div>
        </div>
      </div>

      {/*Mobile menu, show/hide based on mobile menu state.*/}
      <CSSTransition
        in={showMobileMenu}
        classNames="fade"
        timeout={200}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50 bg-dark-full rounded-lg">
          <div className="rounded-lg shadow-lg">
            <div className="rounded-lg shadow-xs divide-y-2 divide-transparent">
              <div className="pt-5 pb-6 px-5 space-y-6">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-12 w-auto"
                      src={cl.url("deadlands", { quality: "auto:eco" })}
                      alt="Deadlands"
                    />
                  </div>
                  <div className="-mr-2">
                    <button
                      onClick={() => setShowMobileMenu(false)}
                      type="button"
                      className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    >
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div className="py-6 px-5 space-y-6">
                <div className="space-y-6">
                  <span className="w-full flex rounded-md shadow-sm">
                    <Link
                      to="/decks/new"
                      className="w-full flex items-center justify-center px-4 py-2 text-center rounded-lg bg-link text-gray-300 text-xl uppercase font-display font-extrabold opacity-75 hover:opacity-100 active:opacity-100"
                    >
                      New Deck
                    </Link>
                  </span>
                  <p className="text-center text-base leading-6 font-medium text-gray-500">
                    <Link
                      to="/cards"
                      className="text-2xl font-semibold shadow-lg font-display text-gray-200 hover:underline"
                    >
                      Cards
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Header;
