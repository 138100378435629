import React, { useState } from "react";
import {
  CardType,
  Expansion,
  Faction,
  GetCardsInputType,
  GetFilters,
  GetFiltersQuery
} from "../types/graphql-global-types";
import { useQueryParams } from "../hooks";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { stringify } from "../lib/urlParams";

type CardTypeStrings = keyof typeof CardType;

interface SelectedState {
  factionIds: Array<number>;
  expansionIds: Array<number>;
  cardTypes: Array<CardType>;
}

const emptyState: SelectedState = {
  cardTypes: [],
  factionIds: [],
  expansionIds: []
};

const getState = (cardSearchVariables: GetCardsInputType): SelectedState => {
  return {
    factionIds:
      cardSearchVariables.factionIds == null ||
      typeof cardSearchVariables.factionIds == "undefined"
        ? []
        : cardSearchVariables.factionIds,
    expansionIds:
      cardSearchVariables.expansionIds == null ||
      typeof cardSearchVariables.expansionIds == "undefined"
        ? []
        : cardSearchVariables.expansionIds,
    cardTypes:
      cardSearchVariables.cardTypes == null ||
      typeof cardSearchVariables.cardTypes == "undefined"
        ? new Array<CardType>()
        : cardSearchVariables.cardTypes
  };
};

const Filters: React.FC = () => {
  const cardSearchVariables: GetCardsInputType = useQueryParams();
  const initialState = getState(cardSearchVariables);
  const filterSize =
    initialState.cardTypes.length +
    initialState.expansionIds.length +
    initialState.factionIds.length;

  const [selectedState, setSelectedState] = useState<SelectedState>(
    initialState
  );
  const [open, setOpen] = useState(false);
  const { loading, error, data } = useQuery<GetFiltersQuery>(GetFilters);

  if (loading) return <span>Loading...</span>;
  if (error) return <span>Error! {error.message}</span>;
  if (!data) return <span>Shouldn't have got here!</span>;

  const factions = data.getFactions;
  const expansions = data.getExpansions;

  const cardTypeInputs = Object.keys(CardType).map(
    (cardType, index: number) => {
      return (
        <Input
          name={cardType}
          checked={cardTypeSelected(selectedState, cardType as CardTypeStrings)}
          key={index}
          onChange={e => {
            setSelectedState(
              addOrRemoveCardType(selectedState, cardType as CardTypeStrings)
            );
          }}
        />
      );
    }
  );

  const factionInputs = factions.map((faction, index) => {
    return (
      <Input
        name={faction.name}
        checked={factionSelected(selectedState, faction)}
        key={index}
        onChange={e => {
          setSelectedState(addOrRemoveFaction(selectedState, faction));
        }}
      />
    );
  });

  const expansionInputs = expansions.map((expansion, index) => {
    return (
      <Input
        name={expansion.name}
        checked={expansionSelected(selectedState, expansion)}
        key={index}
        onChange={() =>
          setSelectedState(addOrRemoveExpansion(selectedState, expansion))
        }
      />
    );
  });

  const showCSS = open ? "" : "hidden";

  const icon = open ? (
    <svg
      className="fill-current w-6 h-6 text-link lg:hidden"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 292.362 292.361"
    >
      <path d="M286.935 197.287L159.028 69.381c-3.613-3.617-7.895-5.424-12.847-5.424s-9.233 1.807-12.85 5.424L5.424 197.287C1.807 200.904 0 205.186 0 210.134s1.807 9.233 5.424 12.847c3.621 3.617 7.902 5.425 12.85 5.425h255.813c4.949 0 9.233-1.808 12.848-5.425 3.613-3.613 5.427-7.898 5.427-12.847s-1.814-9.23-5.427-12.847z" />
    </svg>
  ) : (
    <svg
      className="fill-current w-6 h-6 text-link lg:hidden"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 292.362 292.362"
    >
      <path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z" />
    </svg>
  );

  return (
    <div className="my-4 py-2 px-4 rounded-xl bg-dark">
      <div
        className="flex flex-row justify-between items-center cursor-pointer lg:cursor-auto"
        onClick={() => setOpen(!open)}
      >
        <h2 className="text-2xl font-semibold shadow-lg font-display text-gray-200">
          Filters{filterSize !== 0 ? ` (${filterSize})` : ""}
        </h2>
        {icon}
      </div>

      <div className={`${showCSS} lg:block`}>
        <h3 className="mb-1 text-xl font-semibold shadow-lg font-display text-gray-200">
          Cards
        </h3>
        <div className="flex flex-row flex-wrap justify-start">
          {cardTypeInputs}
        </div>

        <h3 className="mt-4 mb-1 text-xl font-semibold shadow-lg font-display text-gray-200">
          Factions
        </h3>
        <div className="flex flex-row flex-wrap justify-start">
          {factionInputs}
        </div>

        <h3 className="mt-4 mb-1 text-xl font-semibold shadow-lg font-display text-gray-200">
          Expansions
        </h3>
        <div className="flex flex-row flex-wrap justify-start">
          {expansionInputs}
        </div>

        <Link
          to={{
            pathname: "/",
            search: stringify({ ...selectedState, page: 1 })
          }}
          className="my-4 py-2 block w-full text-center rounded-lg bg-link text-gray-200 text-xl uppercase font-display font-extrabold opacity-75 hover:opacity-100 active:opacity-100"
        >
          Filter
        </Link>
        <Link
          to="/"
          onClick={() => setSelectedState(emptyState)}
          className="my-4 py-2 block w-full text-center rounded-lg bg-gray-300 text-gray-800 text-xl uppercase font-display font-extrabold opacity-75 hover:opacity-100 active:opacity-100"
        >
          Reset
        </Link>
      </div>
    </div>
  );
};

const Input: React.FC<{
  name: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ name, checked, onChange }) => {
  return (
    <div className="mb-1">
      <input
        onChange={onChange}
        className="mx-2 w-4 h-4"
        type="checkbox"
        id={`${name}Checkbox`}
        name={`${name}Checkbox`}
        checked={checked}
      />
      <label className="font-thin text-gray-200" htmlFor={`${name}Checkbox`}>
        {name}
      </label>
    </div>
  );
};

const cardTypeSelected = (
  cardSearchVariables: SelectedState,
  cardType: keyof typeof CardType
): boolean => {
  let selected = cardSearchVariables.cardTypes
    ?.map(ct => ct.toString())
    .includes(CardType[cardType]);
  if (selected == null || typeof selected == "undefined") {
    selected = false;
  }
  return selected;
};

const factionSelected = (
  cardSearchVariables: SelectedState,
  faction: Faction
): boolean => {
  let selected = cardSearchVariables.factionIds.includes(faction.id);
  if (selected == null || typeof selected == "undefined") {
    selected = false;
  }
  return selected;
};

const expansionSelected = (
  cardSearchVariables: SelectedState,
  expansion: Expansion
): boolean => {
  let selected = cardSearchVariables.expansionIds.includes(expansion.id);
  if (selected == null || typeof selected == "undefined") {
    selected = false;
  }
  return selected;
};

const addOrRemoveCardType = (
  selectedState: SelectedState,
  cardType: CardTypeStrings
) => {
  let cardTypes: Array<CardType> = [];
  if (cardTypeSelected(selectedState, cardType)) {
    cardTypes = selectedState.cardTypes.filter(
      (ct: CardType) => ct !== CardType[cardType]
    );
  } else {
    cardTypes = [...selectedState?.cardTypes, CardType[cardType]];
  }

  return {
    ...selectedState,
    cardTypes
  };
};

const addOrRemoveFaction = (selectedState: SelectedState, faction: Faction) => {
  let factionIds: Array<number> = [];
  if (factionSelected(selectedState, faction)) {
    factionIds = selectedState.factionIds.filter(
      (eid: number) => eid !== faction.id
    );
  } else {
    factionIds = [...selectedState?.factionIds, faction.id];
  }

  return {
    ...selectedState,
    factionIds
  };
};

const addOrRemoveExpansion = (
  selectedState: SelectedState,
  expansion: Expansion
) => {
  let expansionIds: Array<number> = [];
  if (expansionSelected(selectedState, expansion)) {
    expansionIds = selectedState.expansionIds.filter(
      (eid: number) => eid !== expansion.id
    );
  } else {
    expansionIds = [...selectedState?.expansionIds, expansion.id];
  }

  return {
    ...selectedState,
    expansionIds
  };
};

export default Filters;
