import {
  Column,
  ColumnInstance,
  IdType,
  TableOptions,
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from "react-table";
import { Card } from "../types/graphql-global-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import SkinnyPagination from "./SkinnyPagination";
import { useOnClickOutside } from "../hooks";
import {
  criteriaFilter,
  DefaultColumnFilter,
  textFilter
} from "./cardTable/Filters";

const CardTable: React.FC<{
  columns: Column<Card>[];
  hiddenColumns?: IdType<Card>[];
  cards: Array<Card>;
  startingDudes?: Array<number>;
  initialSelectedRowIds?: Array<number>;
  onMouseOverCard?: (card: Card) => void;
  onMouseLeaveCard?: (card: Card) => void;
  onDblClickCard?: (card: Card, index: number) => void;
  onSelectCard?: (card: Card) => void;
  onDeSelectCard?: (card: Card) => void;
  onDelete?: (index: number) => void;
  onEnter?: (card: Card) => void;
  onDudeIconClick?: (index: number) => void;
  enablePagination?: boolean;
  enableFiltering?: boolean;
}> = ({
  columns,
  hiddenColumns = [],
  cards,
  startingDudes,
  initialSelectedRowIds = [],
  onMouseOverCard,
  onMouseLeaveCard,
  onDblClickCard,
  onSelectCard,
  onDeSelectCard,
  onDelete,
  onEnter,
  onDudeIconClick,
  enablePagination = true,
  enableFiltering = true
}) => {
  const ref = useRef<HTMLTableElement>(null);
  const bottomPaginationRef = useRef<HTMLDivElement>(null);

  const columnsMemo: Column<Card>[] = useMemo(() => {
    return columns;
  }, [columns]);

  const rowProps = (props: any, { row }: any) => [
    props,
    {
      onMouseEnter: () => {
        onMouseOverCard && onMouseOverCard(row.original);
      },
      onMouseLeave: () => {
        onMouseLeaveCard && onMouseLeaveCard(row.original);
      },
      onDoubleClick: () => {
        onDblClickCard && onDblClickCard(row.original, row.index);
      },
      onClick: () => {
        if (row.isSelected) {
          toggleRowSelected(row.id, false);
          onDeSelectCard && onDeSelectCard(row.original);
        } else {
          toggleAllRowsSelected(false);
          toggleRowSelected(row.id, true);
          onSelectCard && onSelectCard(row.original);
        }
      }
    }
  ];

  const tablePlugins: any[] = [];

  let options: TableOptions<Card> = {
    columns: columnsMemo,
    data: cards,
    autoResetSortBy: false,
    initialState: {
      hiddenColumns: ["id", ...hiddenColumns],
      selectedRowIds: initialSelectedRowIds.reduce(
        (memo: { [key: number]: boolean }, value) => {
          memo[value] = true;
          return memo;
        },
        {}
      )
    }
  };

  let defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  );
  let filterTypes = React.useMemo(
    () => ({
      text: textFilter,
      criteria: criteriaFilter
    }),
    []
  );

  if (enableFiltering) {
    tablePlugins.push(useFilters);
    options.defaultColumn = defaultColumn;
    options.filterTypes = filterTypes;
  }

  tablePlugins.push(...[useSortBy, useRowSelect]);

  if (enablePagination) {
    tablePlugins.push(usePagination);
    if (!options.hasOwnProperty("initialState")) {
      options.initialState = {};
    }
    options.initialState = Object.assign({}, options.initialState, {
      pageSize: 30,
      pageIndex: 0
    });
  }

  const cellProps = (props: any, { cell }: any) => [
    props,
    {
      className: cell.column.Header !== "Name" ? "text-center py-1" : "py-1"
    }
  ];

  const tableInstance = useTable(options, ...tablePlugins, hooks => {
    hooks.getRowProps.push(rowProps);
    hooks.getCellProps.push(cellProps);
  });

  const {
    rows,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    toggleAllRowsSelected,
    selectedFlatRows,
    toggleRowSelected,
    prepareRow,
    state: { selectedRowIds }
  } = tableInstance;

  let currentRows = rows;

  if (enablePagination) {
    currentRows = tableInstance.page;
  }

  const deSelectAllRows = useCallback(() => {
    selectedFlatRows.forEach(row => {
      toggleRowSelected(row.id, false);
      onDeSelectCard && onDeSelectCard(row.original);
    });
  }, [selectedFlatRows, toggleRowSelected, onDeSelectCard]);

  useOnClickOutside(ref, e => deSelectAllRows());

  const goPreviousPage = useCallback(() => {
    // If a row is currently selected
    if (selectedFlatRows.length === 1) {
      // Get the first row in the current page.
      const firstRowInPage = tableInstance.page[0];

      // Get its index in the current rows
      const firstRowInPageIndex = rows.indexOf(firstRowInPage);

      // Get the row one back from that.
      const newSelectedRow = rows[firstRowInPageIndex - 1];

      // Deselect all rows
      toggleAllRowsSelected(false);

      // Select the new row
      toggleRowSelected(newSelectedRow.id, true);
      onSelectCard && onSelectCard(newSelectedRow.original);
    }

    tableInstance.previousPage();
  }, [
    selectedFlatRows,
    tableInstance,
    rows,
    toggleAllRowsSelected,
    toggleRowSelected,
    onSelectCard
  ]);

  const goNextPage = useCallback(() => {
    if (selectedFlatRows.length === 1) {
      const lastRowInPage = tableInstance.page[tableInstance.page.length - 1];
      const lastRowInPageIndex = rows.indexOf(lastRowInPage);
      const newSelectedRow = rows[lastRowInPageIndex + 1];

      toggleAllRowsSelected(false);
      toggleRowSelected(newSelectedRow.id, true);
      onSelectCard && onSelectCard(newSelectedRow.original);
    }
    tableInstance.nextPage();
  }, [
    selectedFlatRows,
    tableInstance,
    rows,
    toggleAllRowsSelected,
    toggleRowSelected,
    onSelectCard
  ]);

  const [showFilters, setShowFilters] = useState<boolean>(false);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (enablePagination) {
        if (e.key === "ArrowLeft" && tableInstance.canPreviousPage) {
          goPreviousPage();
          bottomPaginationRef.current?.scrollIntoView({
            block: "end",
            behavior: "smooth"
          });
          return;
        }

        if (e.key === "ArrowRight" && tableInstance.canNextPage) {
          goNextPage();
          ref.current?.scrollIntoView({
            block: "start",
            behavior: "smooth"
          });
          return;
        }
      }

      if (e.key === "ArrowDown") {
        const rowIds: string[] = Object.keys(selectedRowIds);
        const currentRowIndex = currentRows.findIndex(
          row => row.id === rowIds[0]
        );

        if (rowIds.length === 1) {
          if (
            currentRowIndex !== -1 &&
            currentRowIndex < currentRows.length - 1
          ) {
            e.preventDefault();
            const newSelectedRow = currentRows[currentRowIndex + 1];
            toggleAllRowsSelected(false);
            toggleRowSelected(newSelectedRow.id, true);
            onSelectCard && onSelectCard(newSelectedRow.original);
          }
        }
        return;
      }

      if (e.key === "ArrowUp") {
        const rowIds: string[] = Object.keys(selectedRowIds);
        const currentRowIndex = currentRows.findIndex(
          row => row.id === rowIds[0]
        );

        if (rowIds.length === 1) {
          if (currentRowIndex !== -1 && currentRowIndex > 0) {
            e.preventDefault();
            const newSelectedRow = currentRows[currentRowIndex - 1];
            toggleAllRowsSelected(false);
            toggleRowSelected(newSelectedRow.id, true);
            onSelectCard && onSelectCard(newSelectedRow.original);
          }
        }
        return;
      }

      if (e.key === "Escape") {
        deSelectAllRows();
        return;
      }

      const selectedRow = currentRows.find(row => row.isSelected);
      if (e.key === "Delete" && selectedRow && onDelete) {
        onDelete(selectedRow.index);
      }

      if (e.key === "Enter" && selectedRow && onEnter) {
        onEnter(selectedRow.original);
      }
    };

    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [
    tableInstance,
    goNextPage,
    goPreviousPage,
    enablePagination,
    selectedRowIds,
    tableInstance.canPreviousPage,
    tableInstance.canNextPage,
    deSelectAllRows,
    tableInstance.nextPage,
    onSelectCard,
    tableInstance.previousPage,
    rows,
    currentRows,
    toggleAllRowsSelected,
    toggleRowSelected,
    tableInstance.page,
    selectedFlatRows.length,
    tableInstance.pageSize,
    onEnter,
    onDelete,
    showFilters,
    setShowFilters
  ]);

  const filterColumns = tableInstance.allColumns.filter(
    column => column.canFilter
  );

  let bonusFilters: ColumnInstance<Card>[] = [];
  let typeFilterValue = filterColumns.find(c => c.Header === "Type")
    ?.filterValue;

  if (typeFilterValue) {
    bonusFilters = filterColumns.filter(c =>
      (c as any)?.Groups?.includes(typeFilterValue)
    );
  }

  return (
    <div className="p-2 w-full bg-dark rounded-xl text-gray-200">
      {enableFiltering && (
        <div>
          <div className="flex flex-row justify-end items-center space-x-2">
            <button
              onClick={() => tableInstance.setAllFilters([])}
              className="px-4 h-8 shadow border border-black rounded-lg bg-gray-300 text-gray-800 hover:opacity-100 opacity-75 cursor-pointer uppercase font-display"
            >
              Clear
            </button>
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="px-4 h-8 text-center rounded-lg bg-link text-gray-300 uppercase font-display font-extrabold opacity-75 hover:opacity-100 active:opacity-100"
            >
              Filter
            </button>
          </div>

          {showFilters && (
            <div className="my-2 animate__animated animate__fadeIn">
              <div className="p-4 bg-white rounded-xl text-gray-900 space-y-2">
                <div className="lg:grid lg:grid-cols-2 lg:gap-2">
                  <div>
                    {filterColumns
                      .find(c => c.Header === "Value")
                      ?.render("Filter")}
                  </div>
                  <div className="my-1 flex flex-row items-center">
                    {filterColumns
                      .find(c => c.Header === "Suit")
                      ?.render("Filter")}
                  </div>
                </div>

                <div className="mt-2">
                  {filterColumns
                    .find(c => c.Header === "Name")
                    ?.render("Filter")}
                </div>

                <div className="mt-2">
                  {filterColumns
                    .find(c => c.Header === "Text")
                    ?.render("Filter")}
                </div>

                <div className="lg:grid lg:grid-cols-2 lg:grid-rows-2 lg:gap-4">
                  <div className="my-1">
                    {filterColumns
                      .find(c => c.Header === "Inf.")
                      ?.render("Filter")}
                  </div>
                  <div className="my-1">
                    {filterColumns
                      .find(c => c.Header === "Con.")
                      ?.render("Filter")}
                  </div>
                  <div className="my-1">
                    {filterColumns
                      .find(c => c.Header === "Cost")
                      ?.render("Filter")}
                  </div>
                  <div className="my-1">
                    {filterColumns
                      .find(c => c.Header === "Cost")
                      ?.render("Filter")}
                  </div>
                </div>

                {filterColumns.find(c => c.Header === "Type")?.render("Filter")}

                <div className="mt-2 flex flex-row flex-wrap items-center justify-between space-x-4">
                  {bonusFilters.map(bf => bf.render("Filter"))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {enablePagination && (
        <SkinnyPagination
          pageNumber={tableInstance.state.pageIndex + 1}
          totalPages={tableInstance.pageCount}
          firstPageDisabled={!tableInstance.canPreviousPage}
          onFirstPageClick={() => tableInstance.gotoPage(0)}
          previousPageDisabled={!tableInstance.canPreviousPage}
          onPreviousPageClick={() => tableInstance.previousPage()}
          nextPageDisabled={!tableInstance.canNextPage}
          onNextPageClick={() => tableInstance.nextPage()}
          lastPageDisabled={!tableInstance.canNextPage}
          onLastPageClick={() =>
            tableInstance.gotoPage(tableInstance.pageCount - 1)
          }
        />
      )}

      <table
        {...getTableProps()}
        ref={ref}
        className="w-full table-auto cursor-pointer select-none"
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="font-semibold"
            >
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="font-display"
                >
                  {column.render("Header")}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <> &or;</>
                    ) : (
                      <> &and;</>
                    )
                  ) : (
                    ""
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {/* currentRows will either be all rows, or the current page of rows if pagination is enabled */}
          {currentRows.map(row => {
            prepareRow(row);
            const className = row.isSelected ? "bg-link py-2" : "py-2";

            return (
              <tr className={className} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {enablePagination && (
        <SkinnyPagination
          pageNumber={tableInstance.state.pageIndex + 1}
          totalPages={tableInstance.pageCount}
          firstPageDisabled={!tableInstance.canPreviousPage}
          onFirstPageClick={() => tableInstance.gotoPage(0)}
          previousPageDisabled={!tableInstance.canPreviousPage}
          onPreviousPageClick={() => tableInstance.previousPage()}
          nextPageDisabled={!tableInstance.canNextPage}
          onNextPageClick={() => tableInstance.nextPage()}
          lastPageDisabled={!tableInstance.canNextPage}
          onLastPageClick={() =>
            tableInstance.gotoPage(tableInstance.pageCount - 1)
          }
        />
      )}
      <div ref={bottomPaginationRef} />
    </div>
  );
};

export default CardTable;
