import { useState } from "react";
import { Card, Outfit } from "../types/graphql-global-types";
import { Deck } from "../lib/deck";

const useDoomtownDeck = () => {
  const [deck, setDeck] = useState<Deck>(new Deck());

  const replaceDeck = (deck: Deck) => {
    setDeck(deck);
  };

  const setName = (name: string) => {
    setDeck(deck.setName(name));
  };

  const setHome = (aHome: Outfit) => {
    setDeck(deck.setHome(aHome));
  };

  const addCard = (card: Card) => {
    setDeck(deck.addCard(card));
  };

  const removeCard = (index: number) => {
    setDeck(deck.removeCard(index));
  };

  const addStartingDude = (index: number) => {
    setDeck(deck.addStartingDude(index));
  };

  const removeStartingDude = (index: number) => {
    setDeck(deck.removeStartingDude(index));
  };

  return {
    deck,
    replaceDeck,
    setName,
    setHome,
    addCard,
    removeCard,
    addStartingDude,
    removeStartingDude
  };
};

export default useDoomtownDeck;
