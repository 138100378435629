import React from "react";
import cl from "../lib/cloudinary";

const Login: React.FC = () => {
  return (
    <div className="flex flex-col lg:items-center justify-center w-screen h-screen align-middle justify-center">
      <div className="container mx-auto p-20 bg-dark rounded-xl space-y-4">
        <img
          className="mx-auto"
          src={cl.url("deadlands", { quality: "auto:eco" })}
          alt="Deadlands"
        />
        <a
          href="/oauth2/authorization/google"
          className="py-2 px-8 max-w block shadow border border-black rounded-lg bg-link hover:opacity-100 opacity-75 text-gray-300 cursor-pointer text-2xl text-center uppercase font-display"
        >
          Login with Google
        </a>
      </div>
    </div>
  );
};

export default Login;
