import qs from "qs";
import { useLocation } from "react-router";
import { CardSearchQueryVariables } from "../types/graphql-global-types";
import { RefObject, useEffect, useState } from "react";

export { default as useDoomtownDeck } from "./useDoomtownDeck";

export const useQueryParams = (): CardSearchQueryVariables => {
  const location = useLocation();
  const [params, setParams] = useState<CardSearchQueryVariables>(
    parseLocation(location)
  );

  useEffect(() => {
    setParams(parseLocation(location));
  }, [location]);

  return params;
};

const parseLocation = (location: any) => {
  let search = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    decoder: function(str, defaultEncoder, charset, type) {
      if (type === "value" && /^(\d+|\d*\.\d+)$/.test(str)) {
        return parseFloat(str);
      }
      return defaultEncoder(str);
    }
  });

  if (!search["page"] || search["page"] < 1) {
    search.page = 1;
  }

  return search;
};

export const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export function useOnClickOutside<T extends HTMLElement = HTMLDivElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void
) {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current;

      // Do nothing if clicking ref's element or descendent elements

      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }

      handler(event);
    };

    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);

    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };

    // Reload only if ref or handler changes
  }, [ref, handler]);
}
