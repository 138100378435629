import React, { useRef } from "react";
import { Maybe } from "../types/graphql-global-types";

const FileUploadButton: React.FC<{
  onChange: (fileList: Maybe<FileList>) => void;
}> = ({ onChange }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <button
      onClick={() => fileInputRef.current?.click()}
      className="block px-2 bg-link rounded-lg text-gray-200 uppercase font-display font-bold shadow-lg border border-gray-800 hover:text-gray-400"
    >
      <svg
        className="inline mr-2 fill-current w-4 h-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path d="M287.52 224.48c-3.36-3.36-8-5.088-12.736-4.64l-124.448 11.296c-6.176.576-11.52 4.672-13.6 10.496-2.112 5.856-.672 12.384 3.712 16.768l33.952 33.952L4.704 462.048c-6.24 6.24-6.24 16.384 0 22.624l22.624 22.624c6.24 6.272 16.352 6.272 22.624 0L219.648 337.6l33.952 33.952c4.384 4.384 10.912 5.824 16.768 3.744a16.265 16.265 0 0 0 5.856-3.744c2.592-2.592 4.288-6.048 4.608-9.888l11.328-124.448c.448-4.736-1.28-9.376-4.64-12.736z" />
        <path d="M480 0H32C14.336 0 0 14.336 0 32v320h64V64h384v384H160v64h320c17.696 0 32-14.304 32-32V32c0-17.664-14.304-32-32-32z" />
      </svg>
      <input
        onChange={e => onChange(e.currentTarget.files)}
        ref={fileInputRef}
        type="file"
        className="hidden"
      />
      Import
    </button>
  );
};

export default FileUploadButton;
