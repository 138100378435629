import React, { ChangeEvent } from "react";
import { Card, Suit } from "../../types/graphql-global-types";
import { FilterProps, IdType, Row } from "react-table";
import { capitalizeFirstLetter } from "../../lib/string";
import HTMLCardSuit from "../HTMLCardSuit";

const DefaultColumnFilter: React.FC<FilterProps<Card>> = ({
  column: { filterValue, setFilter, Header }
}) => {
  return (
    <label className="flex flex-row">
      <span className="text-gray-700">{Header}</span>
      <input
        className="form-input ml-2 inline-block flex-grow"
        value={filterValue || ""}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
      />
    </label>
  );
};

enum FilterOperator {
  EQUAL = "=",
  LESS_THAN = "<",
  GREATHER_THAN = ">"
}

type FilterCriteria = [
  FilterOperator | undefined,
  string | number | null | undefined
];

const CriteriaFilter: React.FC<FilterProps<Card>> = props => {
  const {
    column: { filterValue = [undefined, undefined], setFilter, id }
  } = props;

  const [operation, value] = filterValue;

  const onOperatorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFilter([e.target.value, value]);
  };

  const onValueChange = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setFilter([operation, e.target.value]);
  };

  return (
    <label className="flex items-center justify-between space-x-2">
      <span className="text-gray-700">{capitalizeFirstLetter(id)}</span>
      <select
        className="form-select"
        value={operation || ""}
        onChange={onOperatorChange}
      >
        <option value={""} />
        <option value={FilterOperator.EQUAL}>=</option>
        <option value={FilterOperator.LESS_THAN}>&lt;</option>
        <option value={FilterOperator.GREATHER_THAN}>&gt;</option>
      </select>
      {props.render(value, onValueChange)}
    </label>
  );
};

const NumberRangeFilter: React.FC<FilterProps<Card>> = props => {
  return (
    <CriteriaFilter
      {...props}
      render={(
        value: string | number | undefined,
        onChange: (e: ChangeEvent<HTMLInputElement>) => void
      ) => (
        <input
          type="number"
          className="form-input block w-20"
          value={value || ""}
          onChange={onChange}
          min={0}
          max={20}
        />
      )}
    />
  );
};

const CardValueFilter: React.FC<FilterProps<Card>> = props => {
  return (
    <CriteriaFilter
      {...props}
      render={(
        value: string | number | undefined,
        onChange: (e: ChangeEvent<HTMLSelectElement>) => void
      ) => (
        <select
          value={value || ""}
          onChange={onChange}
          className="form-select block w-full mt-1"
        >
          <option value={""}></option>
          <option value={1}>A</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
          <option value={7}>7</option>
          <option value={8}>8</option>
          <option value={9}>9</option>
          <option value={10}>10</option>
          <option value={11}>J</option>
          <option value={12}>Q</option>
          <option value={13}>K</option>
        </select>
      )}
    />
  );
};

const CardSuitFilter: React.FC<FilterProps<Card>> = ({
  column: { filterValue, setFilter, id },
  preFilteredRows
}) => {
  const options = React.useMemo(() => {
    return Object.values(Suit);
  }, []);

  return (
    <div className="flex flex-row items-center justify-between w-full">
      {options.map((option: any, key: number) => (
        <label key={key} className="inline-flex items-center">
          <input
            type="radio"
            className="form-radio"
            name="cardSuit"
            value={option}
            checked={filterValue === option}
            onChange={e => {
              if (e.target.checked) {
                setFilter(e.target.value);
              }
            }}
          />
          <HTMLCardSuit suit={option} />
        </label>
      ))}
    </div>
  );
};

const RadioFilter: React.FC<FilterProps<Card>> = ({
  column: { filterValue, setFilter, id },
  preFilteredRows,
  columns
}) => {
  const options = React.useMemo(() => {
    const optionSet = new Set();
    preFilteredRows.forEach(row => optionSet.add(row.values[id]));
    return Array.from(optionSet.values());
  }, [id, preFilteredRows]);

  return (
    <div className="mt-2 flex flex-row flex-wrap items-center justify-between">
      {options.map((option: any, key: number) => (
        <label key={key} className="inline-flex items-center">
          <input
            type="radio"
            className="form-radio"
            name="cardType"
            value={option}
            checked={filterValue === option}
            onChange={e => {
              if (e.target.checked) {
                setFilter(e.target.value);
                columns
                  .filter(c => (c as any)?.Groups?.length > 0)
                  .forEach(c => c.setFilter(undefined));
              }
            }}
          />
          <span className="ml-2">{option}</span>
        </label>
      ))}
    </div>
  );
};

const CheckboxFilter: React.FC<FilterProps<Card>> = ({
  column: { filterValue, setFilter, id, Header }
}) => {
  return (
    <label className="inline-flex items-center">
      <input
        type="checkbox"
        className="form-checkbox"
        name={id}
        value={filterValue}
        checked={filterValue === true}
        onChange={e => {
          setFilter(e.target.checked);
        }}
      />
      <span className="ml-2">{Header}</span>
    </label>
  );
};

/**
 * Filters rows starting with filterValue
 * @param rows The rows to filter
 * @param id the column ID
 * @param filterValue the value to filter by
 * @returns {any}
 */
const textFilter = (
  rows: Array<Row>,
  id: IdType<Card>,
  filterValue: string
): Array<Row> => {
  return rows.filter((row: any) => {
    const rowValue = row.values[id];
    return rowValue !== undefined
      ? String(rowValue)
          .toLowerCase()
          .startsWith(String(filterValue).toLowerCase())
      : true;
  });
};

const getFilterByOperator = (
  operator: FilterOperator,
  id: IdType<Card>,
  value: any
) => {
  if (operator === FilterOperator.EQUAL) {
    return (row: Row) => row.values[id] === parseInt(value, 10);
  } else if (operator === FilterOperator.GREATHER_THAN) {
    return (row: Row) => row.values[id] > parseInt(value, 10);
  } else if (operator === FilterOperator.LESS_THAN) {
    return (row: Row) => row.values[id] < parseInt(value, 10);
  } else {
    return (row: Row) => true;
  }
};

const criteriaFilter = (
  rows: Array<Row>,
  id: IdType<Card>,
  filterValue: FilterCriteria
): Array<Row> => {
  const [operator, value] = filterValue;

  if (!operator || !value) {
    return rows;
  }

  return rows.filter(getFilterByOperator(operator, id, value));
};

export {
  textFilter,
  criteriaFilter,
  DefaultColumnFilter,
  NumberRangeFilter,
  CheckboxFilter,
  CardValueFilter,
  CardSuitFilter,
  RadioFilter
};
