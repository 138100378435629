import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";
import Doomtown from "./components/Doomtown";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import introspectionQueryResultData from "./types/fragmentTypes.json";
import { InMemoryCache } from "apollo-cache-inmemory";
import { BrowserRouter as Router } from "react-router-dom";
import Snowfall from "react-snowfall";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const cache = new InMemoryCache({ fragmentMatcher });

const client = new ApolloClient({
  cache
  // request: operation => {
  //   const token = getCookieValue("XSRF-TOKEN");
  //
  //   operation.setContext({
  //     headers: {
  //       "XSRF-TOKEN": token ? token : ""
  //     }
  //   });
  // }
});

const date = new Date();

const App: React.FC = () => {
  const shouldSnow =
    (date.getMonth() === 11 && date.getDate() > 10) ||
    (date.getMonth() === 0 && date.getDate() < 6);

  return (
    <ApolloProvider client={client}>
      {shouldSnow && <Snowfall snowflakeCount={100} />}
      <Router>
        <Doomtown />
      </Router>
    </ApolloProvider>
  );
};

export default App;
