import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  Card,
  GetSimilarCards,
  GetSimilarCardsQuery,
  GetSimilarCardsQueryVariables
} from "../types/graphql-global-types";
import { Link } from "react-router-dom";
import CardImage from "./CardImage";
import { getName } from "../lib/card";

const Search: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [performSearch, { data }] = useLazyQuery<
    GetSimilarCardsQuery,
    GetSimilarCardsQueryVariables
  >(GetSimilarCards);

  useEffect(() => {
    performSearch({ variables: { name: searchTerm } });
  }, [searchTerm, performSearch]);

  return (
    <div className="relative rounded-xl bg-dark focus-within:bg-dark-full">
      <input
        className="px-2 py-3 mx-2 bg-transparent text-gray-100 placeholder-gray-100 outline-none"
        type="text"
        placeholder="Find a card..."
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      />
      {data && data.getSimilarCards.length > 0 && (
        <SearchResult cards={data.getSimilarCards} />
      )}
    </div>
  );
};

const SearchResult: React.FC<{ cards: Array<Card> }> = ({ cards }) => {
  const cardComponents = cards.map((card, i) => {
    return <SearchCard card={card} key={i} />;
  });

  return (
    <div className="absolute mt-3 bg-dark-full z-20 rounded-xl">
      <ul className="space-y-4 px-4 py-2">{cardComponents}</ul>
    </div>
  );
};

const SearchCard: React.FC<{ card: Card }> = ({ card }) => {
  return (
    <li className="pb-4 border-b border-gray-200 hover:opacity-75">
      <Link to={`/cards/${card.imagePath}`}>
        <div className="inline-flex gap-2">
          <CardImage
            className="rounded w-1/4"
            loadedClassName=" animate__animated animate__fadeIn"
            card={card}
            options={{ quality: "auto:low" }}
            alt={card.name}
          />
          <h3 className="ml-2 font-semibold font-display text-gray-200">
            {getName(card)}
          </h3>
        </div>
      </Link>
    </li>
  );
};

export default Search;
