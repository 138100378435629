import React from "react";
import { Suit } from "../types/graphql-global-types";

const HTMLCardSuit: React.FC<{
  suit?: Suit;
}> = ({ suit }) => {
  switch (suit) {
    case "heart":
      return (
        <span className="px-1 inline-block w-6 text-center bg-gray-200 text-red-900 rounded-full">
          &hearts;
        </span>
      );
    case "diamond":
      return (
        <span className="px-1 inline-block w-6 text-center bg-gray-200 text-red-900 rounded-full">
          &diams;
        </span>
      );
    case "spade":
      return (
        <span className="px-1 inline-block w-6 text-center bg-gray-200 text-black rounded-full">
          &spades;
        </span>
      );
    case "club":
      return (
        <span className="px-1 inline-block w-6 text-center bg-gray-200 text-black rounded-full">
          &clubs;
        </span>
      );
    default:
      return <span>{suit}</span>;
  }
};

export default HTMLCardSuit;
