import React from "react";
import Layout from "./Layout";
import { useQuery } from "@apollo/react-hooks";
import {
  GetCard,
  GetCardQuery,
  GetCardQueryVariables
} from "../types/graphql-global-types";
import { useParams } from "react-router";
import CardImage from "./CardImage";
import { getName } from "../lib/card";
import SidescrollCards from "./SidescrollCards";
import useMetaTags from "react-metatags-hook";
import cl from "../lib/cloudinary";
import { MetaTagsConfig } from "react-metatags-hook/dist/types";
import Search from "./Search";
import Filters from "./Filters";

const Card: React.FC = () => {
  const params = useParams() as GetCardQueryVariables;

  const { loading, error, data } = useQuery<
    GetCardQuery,
    GetCardQueryVariables
  >(GetCard, {
    variables: params
  });

  let meta: MetaTagsConfig = {
    title: "Classic DTDB"
  };

  if (data) {
    meta = {
      title: `Classic DTDB - ${data?.getCard.card.name ?? "Loading..."}`,
      openGraph: {
        image: cl.url(data?.getCard.card.imagePath, {
          quality: "auto:eco",
          crop: "scale",
          height: 299
        })
      }
    };
  }

  useMetaTags(meta);

  if (loading) return <span>Loading...</span>;
  if (error) return <span>Error! {error.message}</span>;
  if (!data) return <span>Shouldn't have got here!</span>;

  return (
    <Layout>
      <div className="px-4 lg:grid-cols-4 lg:grid lg:col-gap-8">
        <div className="lg:col-span-1">
          <Search />
          <Filters />
        </div>
        <div className="lg:col-span-3">
          <h2 className="my-4 text-5xl font-semibold shadow-lg font-display text-gray-200 shadow-lg lg:text-right text-center hidden md:block">
            {getName(data.getCard.card)}
          </h2>
          <div className="my-6 p-8 lg:p-16 bg-dark rounded-xl">
            <CardImage
              className="mx-auto rounded-xl sm:w-5/6 md:w-1/2"
              loadingClassName="animate__animated animate__zoomIn"
              loadedClassName=" animate__animated animate__flipInY"
              wait={600}
              card={data.getCard.card}
              alt={data.getCard.card.name}
            />
          </div>
          {data.getCard.experienced.length > 0 && (
            <SidescrollCards
              title="Experienced"
              cards={data.getCard.experienced}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Card;
