import React from "react";
import { Card } from "../types/graphql-global-types";
import { Link } from "react-router-dom";
import CardImage from "./CardImage";

const SidescrollCards: React.FC<{ title: string; cards: Array<Card> }> = ({
  title,
  cards
}) => {
  const cardImages = cards.map((card, key) => {
    return (
      <Link
        to={`/cards/${card.imagePath}`}
        key={key}
        className="flex flex-grow-0 flex-shrink-0 py-2"
      >
        <CardImage
          className="mx-auto rounded-xl h-64"
          card={card}
          alt={card.name}
        />
      </Link>
    );
  });

  return (
    <div className="my-6 py-2 px-4 bg-dark rounded-xl">
      <h3 className="mb-1 text-xl font-semibold shadow-lg font-display text-gray-200">
        {title}
      </h3>
      <div className="flex flex-no-wrap overflow-x-scroll scrolling-touch space-x-4">
        {cardImages}
      </div>
    </div>
  );
};

export default SidescrollCards;
