import qs from "qs";

export const stringify = (query: any) => {
  return qs.stringify(query, { addQueryPrefix: true });
};

export const parse = (search: string): { [key: string]: string | number } => {
  return qs.parse(search, {
    ignoreQueryPrefix: true,
    decoder: function(str, defaultEncoder, charset, type) {
      if (type === "value" && /^(\d+|\d*\.\d+)$/.test(str)) {
        return parseFloat(str);
      }
      return defaultEncoder(str);
    }
  });
};
