import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import Pagination from "./Pagination";
import { useQueryParams } from "../hooks";
import {
  CardSearch,
  CardSearchQuery,
  CardSearchQueryVariables,
  ICard
} from "../types/graphql-global-types";
import Layout from "./Layout";
import { Link } from "react-router-dom";
import Search from "./Search";
import Filters from "./Filters";
import CardGrid from "./CardGrid";

const Browser: React.FC = () => {
  const cardSearchVariables: CardSearchQueryVariables = useQueryParams();

  const [cardSearch, { loading, error, data }] = useLazyQuery<
    CardSearchQuery,
    CardSearchQueryVariables
  >(CardSearch);

  useEffect(() => {
    cardSearch({
      variables: cardSearchVariables
    });
  }, [cardSearch, cardSearchVariables]);

  if (loading) return <span>Loading...</span>;
  if (error) return <span>Error! {error.message}</span>;
  if (!data) return <span>Shouldn't have got here!</span>;

  return (
    <Layout>
      <div className="px-4 lg:grid-cols-4 lg:grid lg:col-gap-8">
        <div className="lg:col-span-1">
          <Search />
          <Filters />
        </div>
        <div className="lg:gap-8 lg:col-span-3">
          {data.getCards?.cards?.length === 0 ? (
            <Empty />
          ) : (
            <Content data={data} cardSearchVariables={cardSearchVariables} />
          )}
        </div>
      </div>
    </Layout>
  );
};

const Content: React.FC<{
  data: CardSearchQuery;
  cardSearchVariables?: CardSearchQueryVariables;
}> = ({ data, cardSearchVariables }) => {
  return (
    <>
      <Pagination page={cardSearchVariables?.page} result={data.getCards} />

      <CardGrid
        cards={data.getCards.cards}
        className="lg:grid-cols-3 lg:gap-6 xl:grid-cols-4 xl:gap-8"
        wrapper={Link}
        wrapperProps={(card: ICard) => {
          return {
            to: {
              pathname: `/cards/${card.imagePath}`
            }
          };
        }}
      />

      <Pagination page={cardSearchVariables?.page} result={data.getCards} />
      <h2 className="text-2xl text-center lg:text-right font-semibold shadow-lg font-display text-gray-100 block md:hidden">
        Page {cardSearchVariables?.page} of {data?.getCards?.lastPage}
      </h2>
    </>
  );
};

const Empty = () => {
  return (
    <>
      <h2 className="mt-4 mb-1 text-5xl text-center font-semibold shadow-lg font-display text-gray-100">
        No Results
      </h2>
    </>
  );
};

export default Browser;
