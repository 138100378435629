import React from "react";
import { Card } from "../../types/graphql-global-types";
import { CellProps, Column, UseFiltersOptions } from "react-table";
import {
  getCardValue,
  isAction,
  isDeed,
  isDude,
  isGood,
  isOutfit,
  isSpell
} from "../../lib/card";
import HTMLCardSuit from "../HTMLCardSuit";
import {
  CardSuitFilter,
  CardValueFilter,
  CheckboxFilter,
  DefaultColumnFilter,
  NumberRangeFilter,
  RadioFilter
} from "./Filters";
import {
  BulletsCell,
  ControlCell,
  CostCell,
  InfluenceCell,
  NameCell,
  TypeCell
} from "./Cells";

const Id: () => Column<Card> = () => {
  return {
    Header: "id",
    id: "id",
    accessor: "id"
  };
};

const Value: () => Column<Card> = () => {
  return {
    Header: "Value",
    id: "value",
    accessor: "value",
    filter: "criteria",
    Filter: CardValueFilter,
    Cell: (props: any) => {
      const textColor = ["heart", "diamond"].includes(props.row.original.suit)
        ? "text-red-900"
        : "text-black";
      return (
        <span
          className={`px-1 inline-block w-6 bg-gray-200 font-card text-center rounded-full ${textColor}`}
        >
          {getCardValue(props.value)}
        </span>
      );
    }
  };
};

const Suit: () => Column<Card> = () => {
  return {
    Header: "Suit",
    id: "suit",
    accessor: "suit",
    filter: "equals",
    Filter: CardSuitFilter,
    Cell: (props: any) => {
      return <HTMLCardSuit suit={props.value} />;
    }
  };
};

export interface TypeColumnProps {
  startingDudes?: Array<number>;
  onIconClick?: (card: Card) => void;
  onDudeClick?: (index: number) => void;
}

const Type: (props?: TypeColumnProps) => Column<Card> = (props = {}) => {
  const { startingDudes, onIconClick, onDudeClick } = props;
  return {
    Header: "Type",
    id: "type",
    accessor: originalRow => originalRow.__typename,
    filter: "includes",
    Filter: RadioFilter,
    Cell: (props: CellProps<Card>) => {
      return (
        <TypeCell
          card={props.row.original}
          startingDudes={startingDudes}
          onIconClick={onIconClick}
          onDudeClick={onDudeClick}
          rowIndex={props.row.index}
        />
      );
    }
  };
};

const Name: (props?: UseFiltersOptions<Card>) => Column<Card> = (
  props = {}
) => {
  const { disableFilters } = props;

  return {
    Header: "Name",
    accessor: originalRow => originalRow.name,
    id: "name",
    disableFilters,
    Cell: (props: CellProps<Card>) => {
      return <NameCell card={props.row.original} value={props.value} />;
    }
  };
};

const Cost: () => Column<Card> = () => {
  return {
    Header: "Cost",
    id: "cost",
    accessor: card => card.cost,
    filter: "criteria",
    Filter: NumberRangeFilter,
    Cell: (props: CellProps<Card>) => {
      return <CostCell value={props.value} />;
    }
  };
};

const Influence: () => Column<Card> = () => {
  return {
    Header: "Inf.",
    id: "influence",
    filter: "criteria",
    Filter: NumberRangeFilter,
    accessor: card => {
      if (isDude(card) || isGood(card)) {
        return card.influence;
      }
    },
    Cell: (props: CellProps<Card>) => {
      return <InfluenceCell value={props.value} />;
    }
  };
};

const Control: () => Column<Card> = () => {
  return {
    Header: "Con.",
    id: "control",
    accessor: card => {
      if (isDeed(card)) {
        return card.control;
      }
    },
    filter: "criteria",
    Filter: NumberRangeFilter,
    Cell: (props: CellProps<Card>) => {
      return <ControlCell value={props.value} />;
    }
  };
};

const Bullets: () => Column<Card> = () => {
  return {
    Header: "Bullets",
    id: "bulletRatingAndType",
    accessor: card => {
      if (isDude(card) || isGood(card)) {
        return `${card.bulletRating} ${card.bulletType}`;
      }
    },
    Cell: (props: CellProps<Card>) => {
      const card = props.row.original;
      if (isDude(card) || isGood(card)) {
        if (card.bulletRating && card.bulletType) {
          return (
            <BulletsCell
              bulletRating={card.bulletRating}
              bulletType={card.bulletType}
            />
          );
        }
      }
      return <span />;
    }
  };
};

// const GhostRock: () => Column<Card> = () => {
//   return {
//     Header: "GR",
//     id: "ghost_rock",
//     accessor: card => {
//       if (isDude(card)) {
//         return card.upkeep;
//       } else if (isDeed(card)) {
//         return card.production;
//       } else {
//         return null;
//       }
//     },
//     Cell: (props: CellProps<Card>) => {
//       if (props.value) {
//         let value =
//         return <GhostRockCell ghostRock={props.value} />;
//       }
//       return <span />;
//     }
//   };
// };

const Aced: () => Column<Card> = () => {
  return {
    Header: "Aced",
    id: "aced",
    accessor: "aced"
  };
};

const Text: () => Column<Card> = () => {
  return {
    Header: "Text",
    id: "text",
    accessor: "text",
    filter: "includes",
    Filter: DefaultColumnFilter
  };
};

// ACTIONS
const Cheatin: () => Column<Card> = () => {
  return {
    Header: "Cheatin",
    id: "cheatin",
    accessor: card => {
      if (isAction(card)) {
        return card.cheatin;
      }
    },
    Groups: ["Action"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const HarrowedPower: () => Column<Card> = () => {
  return {
    Header: "Harrowed Power",
    id: "harrowedPower",
    accessor: card => {
      if (isAction(card)) {
        return card.harrowedPower;
      }
    },
    Groups: ["Action"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const KungFuPower: () => Column<Card> = () => {
  return {
    Header: "Kung Fu Power",
    id: "kungFuPower",
    accessor: card => {
      if (isAction(card)) {
        return card.kungFuPower;
      }
    },
    Groups: ["Action"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Job: () => Column<Card> = () => {
  return {
    Header: "Job",
    id: "job",
    accessor: card => {
      if (isAction(card)) {
        return card.job;
      }
    },
    Groups: ["Action"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Noon: () => Column<Card> = () => {
  return {
    Header: "Noon",
    id: "noon",
    accessor: card => {
      if (isAction(card) || isSpell(card)) {
        return card.noon;
      }
    },
    Groups: ["Action", "Spell"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Reaction: () => Column<Card> = () => {
  return {
    Header: "Reaction",
    id: "reaction",
    accessor: card => {
      if (isAction(card) || isSpell(card)) {
        return card.reaction;
      }
    },
    Groups: ["Action", "Spell"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Shootout: () => Column<Card> = () => {
  return {
    Header: "Shootout",
    id: "shootout",
    accessor: card => {
      if (isAction(card) || isSpell(card)) {
        return card.shootout;
      }
    },
    Groups: ["Action", "Spell"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

// DEED
const Production: () => Column<Card> = () => {
  return {
    Header: "Production",
    id: "production",
    accessor: card => {
      if (isDeed(card)) {
        return card.production;
      }
    }
  };
};

const Public: () => Column<Card> = () => {
  return {
    Header: "Public",
    id: "public",
    accessor: card => {
      if (isDeed(card)) {
        return card.public;
      }
    },
    Groups: ["Deed"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Brothel: () => Column<Card> = () => {
  return {
    Header: "Brothel",
    id: "brothel",
    accessor: card => {
      if (isDeed(card)) {
        return card.brothel;
      }
    },
    Groups: ["Deed"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Casino: () => Column<Card> = () => {
  return {
    Header: "Casino",
    id: "casino",
    accessor: card => {
      if (isDeed(card)) {
        return card.casino;
      }
    },
    Groups: ["Deed"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Government: () => Column<Card> = () => {
  return {
    Header: "Government",
    id: "government",
    accessor: card => {
      if (isDeed(card)) {
        return card.government;
      }
    },
    Groups: ["Deed"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const HolyGround: () => Column<Card> = () => {
  return {
    Header: "Holy Ground",
    id: "holyGround",
    accessor: card => {
      if (isDeed(card)) {
        return card.holyGround;
      }
    },
    Groups: ["Deed"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const OutOfTown: () => Column<Card> = () => {
  return {
    Header: "OutOfTown",
    id: "outOfTown",
    accessor: card => {
      if (isDeed(card)) {
        return card.outOfTown;
      }
    },
    Groups: ["Deed"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Saloon: () => Column<Card> = () => {
  return {
    Header: "Saloon",
    id: "saloon",
    accessor: card => {
      if (isDeed(card)) {
        return card.saloon;
      }
    },
    Groups: ["Deed"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Strike: () => Column<Card> = () => {
  return {
    Header: "Strike",
    id: "strike",
    accessor: card => {
      if (isDeed(card)) {
        return card.strike;
      }
    },
    Groups: ["Deed"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Improvement: () => Column<Card> = () => {
  return {
    Header: "Improvement",
    id: "improvement",
    accessor: card => {
      if (isDeed(card)) {
        return card.improvement;
      }
    },
    Groups: ["Deed"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

// Dude
const FactionName: () => Column<Card> = () => {
  return {
    Header: "Faction",
    id: "factionName",
    accessor: card => {
      if (isDude(card) || isOutfit(card)) {
        return card.faction?.name;
      }
    }
  };
};

const Experience: () => Column<Card> = () => {
  return {
    Header: "Experience",
    id: "experience",
    accessor: card => {
      if (isDude(card)) {
        return card.experience;
      }
    }
  };
};

const Upkeep: () => Column<Card> = () => {
  return {
    Header: "Upkeep",
    id: "upkeep",
    accessor: card => {
      if (isDude(card)) {
        return card.upkeep;
      }
    }
  };
};

const Huckster: () => Column<Card> = () => {
  return {
    Header: "Huckster",
    id: "huckster",
    accessor: card => {
      if (isDude(card)) {
        return card.huckster;
      }
    },
    Groups: ["Dude"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Blessed: () => Column<Card> = () => {
  return {
    Header: "Blessed",
    id: "blessed",
    accessor: card => {
      if (isDude(card)) {
        return card.blessed;
      }
    },
    Groups: ["Dude"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const MadScientist: () => Column<Card> = () => {
  return {
    Header: "Mad Scientist",
    id: "madScientist",
    accessor: card => {
      if (isDude(card)) {
        return card.madScientist;
      }
    },
    Groups: ["Dude"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Shaman: () => Column<Card> = () => {
  return {
    Header: "Shaman",
    id: "shaman",
    accessor: card => {
      if (isDude(card)) {
        return card.shaman;
      }
    },
    Groups: ["Dude"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const SkillLevel: () => Column<Card> = () => {
  return {
    Header: "Skill Level",
    id: "skillLevel",
    accessor: card => {
      if (isDude(card)) {
        return card.skillLevel;
      }
    }
  };
};

const KungFu: () => Column<Card> = () => {
  return {
    Header: "Kung Fu",
    id: "kungFu",
    accessor: card => {
      if (isDude(card)) {
        return card.kungFu;
      }
    },
    Groups: ["Dude"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Harrowed: () => Column<Card> = () => {
  return {
    Header: "Harrowed",
    id: "harrowed",
    accessor: card => {
      if (isDude(card)) {
        return card.harrowed;
      }
    },
    Groups: ["Dude"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Lycanthrope: () => Column<Card> = () => {
  return {
    Header: "Lycanthrope",
    id: "lycanthrope",
    accessor: card => {
      if (isDude(card)) {
        return card.lycanthrope;
      }
    },
    Groups: ["Dude"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

// Goods
const Sidekick: () => Column<Card> = () => {
  return {
    Header: "Sidekick",
    id: "sidekick",
    accessor: card => {
      if (isGood(card)) {
        return card.sidekick;
      }
    },
    Groups: ["Good"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Gadget: () => Column<Card> = () => {
  return {
    Header: "Gadget",
    id: "gadget",
    accessor: card => {
      if (isGood(card)) {
        return card.gadget;
      }
    },
    Groups: ["Good"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Weapon: () => Column<Card> = () => {
  return {
    Header: "Weapon",
    id: "weapon",
    accessor: card => {
      if (isGood(card)) {
        return card.weapon;
      }
    },
    Groups: ["Good"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Horse: () => Column<Card> = () => {
  return {
    Header: "Horse",
    id: "horse",
    accessor: card => {
      if (isGood(card)) {
        return card.horse;
      }
    },
    Groups: ["Good"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Mystical: () => Column<Card> = () => {
  return {
    Header: "Mystical",
    id: "mystical",
    accessor: card => {
      if (isGood(card)) {
        return card.mystical;
      }
    },
    Groups: ["Good"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

// Spell
const Hex: () => Column<Card> = () => {
  return {
    Header: "Hex",
    id: "hex",
    accessor: card => {
      if (isSpell(card)) {
        return card.hex;
      }
    },
    Groups: ["Spell"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Miracle: () => Column<Card> = () => {
  return {
    Header: "Miracle",
    id: "miracle",
    accessor: card => {
      if (isSpell(card)) {
        return card.miracle;
      }
    },
    Groups: ["Spell"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

const Spirit: () => Column<Card> = () => {
  return {
    Header: "Spirit",
    id: "spirit",
    accessor: card => {
      if (isSpell(card)) {
        return card.spirit;
      }
    },
    Groups: ["Spell"],
    filter: "equal",
    Filter: CheckboxFilter
  };
};

export default {
  Id,
  Value,
  Suit,
  Type,
  Name,
  Text,
  Cost,
  Influence,
  Control,
  Bullets,
  Aced,
  Cheatin,
  HarrowedPower,
  KungFuPower,
  Job,
  Noon,
  Reaction,
  Shootout,
  Production,
  Public,
  Brothel,
  Casino,
  Government,
  HolyGround,
  OutOfTown,
  Saloon,
  Strike,
  Improvement,
  FactionName,
  Experience,
  Upkeep,
  Huckster,
  Blessed,
  MadScientist,
  Shaman,
  SkillLevel,
  KungFu,
  Harrowed,
  Lycanthrope,
  Sidekick,
  Gadget,
  Weapon,
  Horse,
  Mystical,
  Hex,
  Miracle,
  Spirit
};
