import gql from "graphql-tag";
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Action = ICard & {
  __typename?: "Action";
  id: Scalars["Int"];
  name: Scalars["String"];
  imagePath: Scalars["String"];
  value?: Maybe<Scalars["Int"]>;
  cost?: Maybe<Scalars["Int"]>;
  suit?: Maybe<Suit>;
  text?: Maybe<Scalars["String"]>;
  rarity?: Maybe<Scalars["String"]>;
  aced?: Maybe<Scalars["Boolean"]>;
  uuid: Scalars["String"];
  bulletType?: Maybe<BulletType>;
  bulletBonus?: Maybe<Scalars["Int"]>;
  cheatin?: Maybe<Scalars["Boolean"]>;
  harrowedPower?: Maybe<Scalars["Boolean"]>;
  kungFuPower?: Maybe<Scalars["Boolean"]>;
  job?: Maybe<Scalars["Boolean"]>;
  noon?: Maybe<Scalars["Boolean"]>;
  reaction?: Maybe<Scalars["Boolean"]>;
  shootout?: Maybe<Scalars["Boolean"]>;
};

export enum BulletType {
  Stud = "stud",
  Draw = "draw"
}

export type Card = Action | Deed | Dude | Event | Good | Joker | Outfit | Spell;

export enum CardType {
  Action = "ACTION",
  Deed = "DEED",
  Dude = "DUDE",
  Event = "EVENT",
  Good = "GOOD",
  Joker = "JOKER",
  Outfit = "OUTFIT",
  Spell = "SPELL"
}

export type Deed = ICard & {
  __typename?: "Deed";
  id: Scalars["Int"];
  name: Scalars["String"];
  imagePath: Scalars["String"];
  value?: Maybe<Scalars["Int"]>;
  cost?: Maybe<Scalars["Int"]>;
  suit?: Maybe<Suit>;
  text?: Maybe<Scalars["String"]>;
  rarity?: Maybe<Scalars["String"]>;
  aced?: Maybe<Scalars["Boolean"]>;
  uuid: Scalars["String"];
  control?: Maybe<Scalars["Int"]>;
  experience?: Maybe<Scalars["Int"]>;
  production?: Maybe<Scalars["Int"]>;
  public?: Maybe<Scalars["Boolean"]>;
  brothel?: Maybe<Scalars["Boolean"]>;
  casino?: Maybe<Scalars["Boolean"]>;
  government?: Maybe<Scalars["Boolean"]>;
  holyGround?: Maybe<Scalars["Boolean"]>;
  outOfTown?: Maybe<Scalars["Boolean"]>;
  saloon?: Maybe<Scalars["Boolean"]>;
  strike?: Maybe<Scalars["Boolean"]>;
  improvement?: Maybe<Scalars["Boolean"]>;
};

export type Dude = ICard & {
  __typename?: "Dude";
  id: Scalars["Int"];
  name: Scalars["String"];
  imagePath: Scalars["String"];
  value?: Maybe<Scalars["Int"]>;
  cost?: Maybe<Scalars["Int"]>;
  suit?: Maybe<Suit>;
  text?: Maybe<Scalars["String"]>;
  rarity?: Maybe<Scalars["String"]>;
  aced?: Maybe<Scalars["Boolean"]>;
  uuid: Scalars["String"];
  bulletRating?: Maybe<Scalars["Int"]>;
  bulletType?: Maybe<BulletType>;
  faction?: Maybe<Faction>;
  experience?: Maybe<Scalars["Int"]>;
  influence?: Maybe<Scalars["Int"]>;
  upkeep?: Maybe<Scalars["Int"]>;
  huckster?: Maybe<Scalars["Boolean"]>;
  blessed?: Maybe<Scalars["Boolean"]>;
  madScientist?: Maybe<Scalars["Boolean"]>;
  shaman?: Maybe<Scalars["Boolean"]>;
  skillLevel?: Maybe<Scalars["Int"]>;
  kungFu?: Maybe<Scalars["Boolean"]>;
  harrowed?: Maybe<Scalars["Boolean"]>;
  lycanthrope?: Maybe<Scalars["Boolean"]>;
};

export type Event = ICard & {
  __typename?: "Event";
  id: Scalars["Int"];
  name: Scalars["String"];
  imagePath: Scalars["String"];
  value?: Maybe<Scalars["Int"]>;
  cost?: Maybe<Scalars["Int"]>;
  suit?: Maybe<Suit>;
  text?: Maybe<Scalars["String"]>;
  rarity?: Maybe<Scalars["String"]>;
  aced?: Maybe<Scalars["Boolean"]>;
  uuid: Scalars["String"];
};

export type Expansion = {
  __typename?: "Expansion";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type Faction = {
  __typename?: "Faction";
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type GetCardInputType = {
  imagePath: Scalars["String"];
};

export type GetCardResult = {
  __typename?: "GetCardResult";
  card: Card;
  experienced: Array<Card>;
};

export type GetCardsInputType = {
  cardTypes?: Maybe<Array<CardType>>;
  expansionIds?: Maybe<Array<Scalars["Int"]>>;
  factionIds?: Maybe<Array<Scalars["Int"]>>;
  page?: Maybe<Scalars["Int"]>;
};

export type GetCardsResult = {
  __typename?: "GetCardsResult";
  cards: Array<Card>;
  lastPage?: Maybe<Scalars["Int"]>;
  hasNextPage?: Maybe<Scalars["Boolean"]>;
};

export type GetSimilarCardsInputType = {
  name: Scalars["String"];
};

export type Good = ICard & {
  __typename?: "Good";
  id: Scalars["Int"];
  name: Scalars["String"];
  imagePath: Scalars["String"];
  value?: Maybe<Scalars["Int"]>;
  cost?: Maybe<Scalars["Int"]>;
  suit?: Maybe<Suit>;
  text?: Maybe<Scalars["String"]>;
  rarity?: Maybe<Scalars["String"]>;
  aced?: Maybe<Scalars["Boolean"]>;
  uuid: Scalars["String"];
  bulletRating?: Maybe<Scalars["Int"]>;
  bulletType?: Maybe<BulletType>;
  influence?: Maybe<Scalars["Int"]>;
  sidekick?: Maybe<Scalars["Boolean"]>;
  gadget?: Maybe<Scalars["Boolean"]>;
  weapon?: Maybe<Scalars["Boolean"]>;
  horse?: Maybe<Scalars["Boolean"]>;
  mystical?: Maybe<Scalars["Boolean"]>;
};

export type ICard = {
  id: Scalars["Int"];
  name: Scalars["String"];
  imagePath: Scalars["String"];
  value?: Maybe<Scalars["Int"]>;
  cost?: Maybe<Scalars["Int"]>;
  suit?: Maybe<Suit>;
  text?: Maybe<Scalars["String"]>;
  rarity?: Maybe<Scalars["String"]>;
  aced?: Maybe<Scalars["Boolean"]>;
  uuid: Scalars["String"];
};

export type Joker = ICard & {
  __typename?: "Joker";
  id: Scalars["Int"];
  name: Scalars["String"];
  imagePath: Scalars["String"];
  value?: Maybe<Scalars["Int"]>;
  cost?: Maybe<Scalars["Int"]>;
  suit?: Maybe<Suit>;
  text?: Maybe<Scalars["String"]>;
  rarity?: Maybe<Scalars["String"]>;
  aced?: Maybe<Scalars["Boolean"]>;
  uuid: Scalars["String"];
};

export type Outfit = ICard & {
  __typename?: "Outfit";
  id: Scalars["Int"];
  name: Scalars["String"];
  imagePath: Scalars["String"];
  value?: Maybe<Scalars["Int"]>;
  cost?: Maybe<Scalars["Int"]>;
  suit?: Maybe<Suit>;
  production?: Maybe<Scalars["Int"]>;
  faction?: Maybe<Faction>;
  text?: Maybe<Scalars["String"]>;
  rarity?: Maybe<Scalars["String"]>;
  aced?: Maybe<Scalars["Boolean"]>;
  uuid: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  getAllCards: Array<Card>;
  getCards: GetCardsResult;
  getSimilarCards: Array<Card>;
  getExpansions: Array<Expansion>;
  getFactions: Array<Faction>;
  getCard: GetCardResult;
  getUser?: Maybe<User>;
};

export type QueryGetCardsArgs = {
  params?: Maybe<GetCardsInputType>;
};

export type QueryGetSimilarCardsArgs = {
  params: GetSimilarCardsInputType;
};

export type QueryGetCardArgs = {
  params: GetCardInputType;
};

export type Spell = ICard & {
  __typename?: "Spell";
  id: Scalars["Int"];
  name: Scalars["String"];
  imagePath: Scalars["String"];
  value?: Maybe<Scalars["Int"]>;
  cost?: Maybe<Scalars["Int"]>;
  suit?: Maybe<Suit>;
  text?: Maybe<Scalars["String"]>;
  rarity?: Maybe<Scalars["String"]>;
  aced?: Maybe<Scalars["Boolean"]>;
  uuid: Scalars["String"];
  hex?: Maybe<Scalars["Boolean"]>;
  miracle?: Maybe<Scalars["Boolean"]>;
  spirit?: Maybe<Scalars["Boolean"]>;
  noon?: Maybe<Scalars["Boolean"]>;
  reaction?: Maybe<Scalars["Boolean"]>;
  shootout?: Maybe<Scalars["Boolean"]>;
};

export enum Suit {
  Heart = "heart",
  Diamond = "diamond",
  Club = "club",
  Spade = "spade"
}

export type User = {
  __typename?: "User";
  name: Scalars["String"];
};

export type AllCardsQueryVariables = {};

export type AllCardsQuery = { __typename?: "Query" } & {
  getAllCards: Array<
    | ({ __typename: "Action" } & Pick<
        Action,
        | "id"
        | "name"
        | "imagePath"
        | "value"
        | "cost"
        | "suit"
        | "text"
        | "rarity"
        | "aced"
        | "uuid"
        | "bulletType"
        | "bulletBonus"
        | "cheatin"
        | "harrowedPower"
        | "kungFuPower"
        | "job"
        | "noon"
        | "reaction"
        | "shootout"
      >)
    | ({ __typename: "Deed" } & Pick<
        Deed,
        | "id"
        | "name"
        | "imagePath"
        | "value"
        | "cost"
        | "suit"
        | "text"
        | "rarity"
        | "aced"
        | "uuid"
        | "control"
        | "experience"
        | "production"
        | "public"
        | "brothel"
        | "casino"
        | "government"
        | "holyGround"
        | "outOfTown"
        | "saloon"
        | "strike"
        | "improvement"
      >)
    | ({ __typename: "Dude" } & Pick<
        Dude,
        | "id"
        | "name"
        | "imagePath"
        | "value"
        | "cost"
        | "suit"
        | "text"
        | "rarity"
        | "aced"
        | "uuid"
        | "bulletRating"
        | "bulletType"
        | "experience"
        | "influence"
        | "upkeep"
        | "huckster"
        | "blessed"
        | "madScientist"
        | "shaman"
        | "skillLevel"
        | "kungFu"
        | "harrowed"
        | "lycanthrope"
      > & {
          faction?: Maybe<
            { __typename?: "Faction" } & Pick<Faction, "id" | "name">
          >;
        })
    | ({ __typename: "Event" } & Pick<
        Event,
        | "id"
        | "name"
        | "imagePath"
        | "value"
        | "cost"
        | "suit"
        | "text"
        | "rarity"
        | "aced"
        | "uuid"
      >)
    | ({ __typename: "Good" } & Pick<
        Good,
        | "id"
        | "name"
        | "imagePath"
        | "value"
        | "cost"
        | "suit"
        | "text"
        | "rarity"
        | "aced"
        | "uuid"
        | "bulletRating"
        | "bulletType"
        | "influence"
        | "sidekick"
        | "gadget"
        | "weapon"
        | "horse"
        | "mystical"
      >)
    | ({ __typename: "Joker" } & Pick<
        Joker,
        | "id"
        | "name"
        | "imagePath"
        | "value"
        | "cost"
        | "suit"
        | "text"
        | "rarity"
        | "aced"
        | "uuid"
      >)
    | ({ __typename: "Outfit" } & Pick<
        Outfit,
        | "id"
        | "name"
        | "imagePath"
        | "value"
        | "cost"
        | "suit"
        | "text"
        | "rarity"
        | "aced"
        | "uuid"
        | "production"
      > & {
          faction?: Maybe<
            { __typename?: "Faction" } & Pick<Faction, "id" | "name">
          >;
        })
    | ({ __typename: "Spell" } & Pick<
        Spell,
        | "id"
        | "name"
        | "imagePath"
        | "value"
        | "cost"
        | "suit"
        | "text"
        | "rarity"
        | "aced"
        | "uuid"
        | "hex"
        | "miracle"
        | "spirit"
        | "noon"
        | "reaction"
        | "shootout"
      >)
  >;
};

export type GetCardQueryVariables = {
  imagePath: Scalars["String"];
};

export type GetCardQuery = { __typename?: "Query" } & {
  getCard: { __typename?: "GetCardResult" } & {
    card:
      | ({ __typename: "Action" } & Pick<
          Action,
          | "id"
          | "name"
          | "imagePath"
          | "value"
          | "cost"
          | "suit"
          | "uuid"
          | "bulletType"
          | "bulletBonus"
        >)
      | ({ __typename: "Deed" } & Pick<
          Deed,
          | "id"
          | "name"
          | "imagePath"
          | "value"
          | "cost"
          | "suit"
          | "uuid"
          | "control"
        >)
      | ({ __typename: "Dude" } & Pick<
          Dude,
          | "id"
          | "name"
          | "imagePath"
          | "value"
          | "cost"
          | "suit"
          | "uuid"
          | "bulletRating"
          | "bulletType"
        > & {
            faction?: Maybe<
              { __typename?: "Faction" } & Pick<Faction, "id" | "name">
            >;
          })
      | ({ __typename: "Event" } & Pick<
          Event,
          "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
        >)
      | ({ __typename: "Good" } & Pick<
          Good,
          | "id"
          | "name"
          | "imagePath"
          | "value"
          | "cost"
          | "suit"
          | "uuid"
          | "bulletRating"
          | "bulletType"
        >)
      | ({ __typename: "Joker" } & Pick<
          Joker,
          "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
        >)
      | ({ __typename: "Outfit" } & Pick<
          Outfit,
          | "id"
          | "name"
          | "imagePath"
          | "value"
          | "cost"
          | "suit"
          | "uuid"
          | "production"
        > & {
            faction?: Maybe<
              { __typename?: "Faction" } & Pick<Faction, "id" | "name">
            >;
          })
      | ({ __typename: "Spell" } & Pick<
          Spell,
          "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
        >);
    experienced: Array<
      | ({ __typename: "Action" } & Pick<
          Action,
          "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
        >)
      | ({ __typename: "Deed" } & Pick<
          Deed,
          "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
        >)
      | ({ __typename: "Dude" } & Pick<
          Dude,
          | "id"
          | "name"
          | "imagePath"
          | "value"
          | "cost"
          | "suit"
          | "uuid"
          | "experience"
        >)
      | ({ __typename: "Event" } & Pick<
          Event,
          "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
        >)
      | ({ __typename: "Good" } & Pick<
          Good,
          "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
        >)
      | ({ __typename: "Joker" } & Pick<
          Joker,
          "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
        >)
      | ({ __typename: "Outfit" } & Pick<
          Outfit,
          "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
        >)
      | ({ __typename: "Spell" } & Pick<
          Spell,
          "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
        >)
    >;
  };
};

export type CardSearchQueryVariables = {
  page: Scalars["Int"];
  cardTypes?: Maybe<Array<CardType>>;
  expansionIds?: Maybe<Array<Scalars["Int"]>>;
  factionIds?: Maybe<Array<Scalars["Int"]>>;
};

export type CardSearchQuery = { __typename?: "Query" } & {
  getCards: { __typename?: "GetCardsResult" } & Pick<
    GetCardsResult,
    "lastPage" | "hasNextPage"
  > & {
      cards: Array<
        | ({ __typename: "Action" } & Pick<
            Action,
            | "id"
            | "name"
            | "imagePath"
            | "value"
            | "cost"
            | "suit"
            | "uuid"
            | "bulletType"
            | "bulletBonus"
          >)
        | ({ __typename: "Deed" } & Pick<
            Deed,
            | "id"
            | "name"
            | "imagePath"
            | "value"
            | "cost"
            | "suit"
            | "uuid"
            | "control"
          >)
        | ({ __typename: "Dude" } & Pick<
            Dude,
            | "id"
            | "name"
            | "imagePath"
            | "value"
            | "cost"
            | "suit"
            | "uuid"
            | "bulletRating"
            | "bulletType"
          > & {
              faction?: Maybe<
                { __typename?: "Faction" } & Pick<Faction, "id" | "name">
              >;
            })
        | ({ __typename: "Event" } & Pick<
            Event,
            "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
          >)
        | ({ __typename: "Good" } & Pick<
            Good,
            | "id"
            | "name"
            | "imagePath"
            | "value"
            | "cost"
            | "suit"
            | "uuid"
            | "bulletRating"
            | "bulletType"
          >)
        | ({ __typename: "Joker" } & Pick<
            Joker,
            "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
          >)
        | ({ __typename: "Outfit" } & Pick<
            Outfit,
            | "id"
            | "name"
            | "imagePath"
            | "value"
            | "cost"
            | "suit"
            | "uuid"
            | "production"
          > & {
              faction?: Maybe<
                { __typename?: "Faction" } & Pick<Faction, "id" | "name">
              >;
            })
        | ({ __typename: "Spell" } & Pick<
            Spell,
            "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
          >)
      >;
    };
};

export type GetFiltersQueryVariables = {};

export type GetFiltersQuery = { __typename?: "Query" } & {
  getExpansions: Array<
    { __typename?: "Expansion" } & Pick<Expansion, "id" | "name">
  >;
  getFactions: Array<{ __typename?: "Faction" } & Pick<Faction, "id" | "name">>;
};

export type GetSimilarCardsQueryVariables = {
  name: Scalars["String"];
};

export type GetSimilarCardsQuery = { __typename?: "Query" } & {
  getSimilarCards: Array<
    | ({ __typename: "Action" } & Pick<
        Action,
        "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
      >)
    | ({ __typename: "Deed" } & Pick<
        Deed,
        "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
      >)
    | ({ __typename: "Dude" } & Pick<
        Dude,
        "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
      >)
    | ({ __typename: "Event" } & Pick<
        Event,
        "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
      >)
    | ({ __typename: "Good" } & Pick<
        Good,
        "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
      >)
    | ({ __typename: "Joker" } & Pick<
        Joker,
        "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
      >)
    | ({ __typename: "Outfit" } & Pick<
        Outfit,
        "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
      >)
    | ({ __typename: "Spell" } & Pick<
        Spell,
        "id" | "name" | "imagePath" | "value" | "cost" | "suit" | "uuid"
      >)
  >;
};

export const AllCards = gql`
  query AllCards {
    getAllCards {
      __typename
      ... on ICard {
        id
        name
        imagePath
        value
        cost
        suit
        text
        rarity
        aced
        uuid
      }
      ... on Action {
        bulletType
        bulletBonus
        cheatin
        harrowedPower
        kungFuPower
        job
        noon
        reaction
        shootout
      }
      ... on Deed {
        control
        experience
        production
        public
        brothel
        casino
        government
        holyGround
        outOfTown
        saloon
        strike
        improvement
      }
      ... on Dude {
        bulletRating
        bulletType
        faction {
          id
          name
        }
        experience
        influence
        upkeep
        huckster
        blessed
        madScientist
        shaman
        skillLevel
        kungFu
        harrowed
        lycanthrope
      }
      ... on Good {
        bulletRating
        bulletType
        influence
        sidekick
        gadget
        weapon
        horse
        mystical
      }
      ... on Outfit {
        production
        faction {
          id
          name
        }
      }
      ... on Spell {
        hex
        miracle
        spirit
        noon
        reaction
        shootout
      }
    }
  }
`;
export const GetCard = gql`
  query GetCard($imagePath: String!) {
    getCard(params: { imagePath: $imagePath }) {
      card {
        __typename
        ... on ICard {
          id
          name
          imagePath
          value
          cost
          suit
          uuid
        }
        ... on Action {
          bulletType
          bulletBonus
        }
        ... on Deed {
          control
        }
        ... on Dude {
          bulletRating
          bulletType
          faction {
            id
            name
          }
        }
        ... on Good {
          bulletRating
          bulletType
        }
        ... on Outfit {
          production
          faction {
            id
            name
          }
        }
      }
      experienced {
        __typename
        ... on ICard {
          id
          name
          imagePath
          value
          cost
          suit
          uuid
        }
        ... on Dude {
          experience
        }
      }
    }
  }
`;
export const CardSearch = gql`
  query CardSearch(
    $page: Int!
    $cardTypes: [CardType!]
    $expansionIds: [Int!]
    $factionIds: [Int!]
  ) {
    getCards(
      params: {
        page: $page
        cardTypes: $cardTypes
        expansionIds: $expansionIds
        factionIds: $factionIds
      }
    ) {
      lastPage
      hasNextPage
      cards {
        __typename
        ... on ICard {
          id
          name
          imagePath
          value
          cost
          suit
          uuid
        }
        ... on Action {
          bulletType
          bulletBonus
        }
        ... on Deed {
          control
        }
        ... on Dude {
          bulletRating
          bulletType
          faction {
            id
            name
          }
        }
        ... on Good {
          bulletRating
          bulletType
        }
        ... on Outfit {
          production
          faction {
            id
            name
          }
        }
      }
    }
  }
`;
export const GetFilters = gql`
  query GetFilters {
    getExpansions {
      id
      name
    }
    getFactions {
      id
      name
    }
  }
`;
export const GetSimilarCards = gql`
  query GetSimilarCards($name: String!) {
    getSimilarCards(params: { name: $name }) {
      __typename
      ... on ICard {
        id
        name
        imagePath
        value
        cost
        suit
        uuid
      }
    }
  }
`;
