import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";

const Layout: React.FC = ({ children }) => {
  return (
    <div className="mx-auto my-4 px-4">
      <Header />

      {children}

      <footer className="mt-4 py-2 px-4 text-center lg:col-span-3 lg:col-start-2 rounded-xl text-gray-400">
        <ul className="block">
          <li>
            <Link className="" to="/privacy">
              Privacy Policy
            </Link>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Layout;
