import React from "react";
import { Link } from "react-router-dom";
import qs from "qs";
import { GetCardsResult, Maybe } from "../types/graphql-global-types";
import { stringify } from "../lib/urlParams";

interface PaginationParams {
  page: number | null | undefined;
  result: Maybe<GetCardsResult> | undefined;
}

const toPage = (location: any, page: (i: number) => number): Location => {
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  query.page = page(parseInt(query.page));
  return {
    ...location,
    search: stringify(query)
  };
};

const Pagination: React.FC<PaginationParams> = ({ page, result }) => {
  let actualPage = 1;
  if (page != null && typeof page !== "undefined") {
    actualPage = page;
  }

  return (
    <div className="flex flex-row justify-between content-center mb-4 rounded-xl">
      <div>
        <PossibleLink
          disabled={page === 1}
          to={location => toPage(location, () => 1)}
          activeClasses="px-3 h-12 mr-2 shadow border border-black rounded-lg bg-link hover:opacity-100 opacity-75 text-gray-300 cursor-pointer text-3xl uppercase font-display font-extrabold"
          disabledClasses="px-3 h-12 mr-2 shadow border border-black rounded-lg bg-link-disabled opacity-75 text-gray-100 cursor-auto text-3xl uppercase font-display font-extrabold"
        >
          &lt;&lt;
        </PossibleLink>
        <PossibleLink
          disabled={page === 1}
          to={location => toPage(location, i => actualPage - 1)}
          activeClasses="px-8 h-12 shadow border border-black rounded-lg bg-link hover:opacity-100 opacity-75 text-gray-300 cursor-pointer text-3xl uppercase font-display font-extrabold"
          disabledClasses="px-8 h-12 shadow border border-black rounded-lg bg-link-disabled opacity-75 text-gray-100 cursor-auto text-3xl uppercase font-display font-extrabold"
        >
          &lt;
        </PossibleLink>
      </div>

      <h2 className="text-2xl text-center lg:text-right font-semibold shadow-lg font-display text-gray-100 hidden md:block">
        Page {page} of {result?.lastPage}
      </h2>

      <div>
        <PossibleLink
          disabled={!result?.hasNextPage || false}
          to={location => toPage(location, i => actualPage + 1)}
          activeClasses="px-8 h-12 shadow border border-black rounded-lg bg-link hover:opacity-100 opacity-75 text-gray-300 cursor-pointer text-3xl uppercase font-display font-extrabold"
          disabledClasses="px-8 h-12 shadow border border-black rounded-lg bg-link-disabled opacity-75 text-gray-100 cursor-auto text-3xl uppercase font-display font-extrabold"
        >
          &gt;
        </PossibleLink>
        <PossibleLink
          disabled={result?.lastPage === page}
          to={location => toPage(location, i => result?.lastPage || 1)}
          activeClasses="px-3 h-12 ml-2 shadow border border-black rounded-lg bg-link hover:opacity-100 opacity-75 text-gray-300 cursor-pointer text-3xl uppercase font-display font-extrabold"
          disabledClasses="px-3 h-12 ml-2 shadow border border-black rounded-lg bg-link-disabled opacity-75 text-gray-100 cursor-auto text-3xl uppercase font-display font-extrabold"
        >
          &gt;&gt;
        </PossibleLink>
      </div>
    </div>
  );
};

const PossibleLink: React.FC<{
  disabled: boolean;
  activeClasses: string;
  disabledClasses: string;
  to: (location: any) => Location;
}> = ({ disabled, activeClasses, disabledClasses, to, children }) => {
  if (disabled) {
    return <span className={disabledClasses}>{children}</span>;
  }

  return (
    <Link to={to} className={activeClasses}>
      {children}
    </Link>
  );
};

export default Pagination;
