import React, { MouseEventHandler } from "react";
import { BulletType, Card } from "../../types/graphql-global-types";
import { getFactionImg, isDude } from "../../lib/card";
import CardIcon from "../CardIcon";
import { TypeColumnProps } from "./Columns";
import cl from "../../lib/cloudinary";

interface TypeCellProps extends TypeColumnProps {
  card: Card;
  rowIndex: number;
}

export const TypeCell: React.FC<TypeCellProps> = ({
  card,
  startingDudes,
  onIconClick,
  onDudeClick,
  rowIndex
}) => {
  let onClick: MouseEventHandler<HTMLSpanElement> = () => {};
  let className =
    "p-1 -mb-2 border border-4 border-black inline-block rounded-full";

  if (onIconClick) {
    onClick = e => {
      e.stopPropagation();
      onIconClick(card);
    };

    className += " bg-link hover:bg-red-900";
  }

  if (onDudeClick && isDude(card)) {
    onClick = e => {
      e.stopPropagation();
      onDudeClick(rowIndex);
    };

    if (startingDudes && startingDudes.includes(rowIndex)) {
      className += " bg-gray-200 hover:bg-gray-400";
    } else {
      className += " bg-link hover:bg-red-900";
    }
  } else {
    className += " bg-link";
  }

  return (
    <span onClick={onClick} className={className}>
      <CardIcon
        className="w-4 h-4 text-black fill-current"
        cardType={card.__typename}
      />
    </span>
  );
};

interface NameCellProps {
  card: Card;
  value: string;
}

export const NameCell: React.FC<NameCellProps> = ({ card, value }) => {
  let factionImgUrl = getFactionImg(card);
  let factionImg = null;

  if (factionImgUrl !== null) {
    factionImg = (
      <span
        className="inline-block h-6 w-6 float-right bg-contain bg-no-repeat bg-center text-center text-black font-bold text-lg"
        style={{
          backgroundImage: `url(${cl.url(factionImgUrl, {
            quality: "auto:eco"
          })})`
        }}
      />
    );
  }
  return (
    <span className="px-2 font-display">
      {value}
      {factionImg}
    </span>
  );
};

interface CostCellProps {
  value: number | null | undefined;
}

export const CostCell: React.FC<CostCellProps> = ({ value }) => {
  if (value !== null) {
    return (
      <span
        className="inline-block h-6 w-6 font-card bg-contain bg-no-repeat bg-center text-center text-black font-card font-bold text-lg"
        style={{
          backgroundImage: `url(${cl.url("cost_vowkrp.png", {
            quality: "auto:eco"
          })})`
        }}
      >
        {value}
      </span>
    );
  } else {
    return <span />;
  }
};

interface InfluenceCellProps {
  value: number | null | undefined;
}

export const InfluenceCell: React.FC<InfluenceCellProps> = ({ value }) => {
  if (value) {
    return (
      <span
        className="inline-block h-6 w-6 bg-contain bg-no-repeat bg-center text-center text-black font-card font-bold text-lg"
        style={{
          backgroundImage: `url(${cl.url("pc_influence_k5s6al", {
            quality: "auto:eco"
          })})`
        }}
      >
        {value}
      </span>
    );
  } else {
    return <span />;
  }
};

interface ControlCellProps {
  value: number | null | undefined;
}

export const ControlCell: React.FC<ControlCellProps> = ({ value }) => {
  if (value) {
    return (
      <span
        className="inline-block h-6 w-6 bg-contain bg-no-repeat bg-center text-center text-black font-card font-bold text-lg"
        style={{
          backgroundImage: `url(${cl.url("pc_control_yozdqm", {
            quality: "auto:eco"
          })})`
        }}
      >
        {value}
      </span>
    );
  } else {
    return <span />;
  }
};

interface BulletsCellProp {
  bulletRating: number | null | undefined;
  bulletType: BulletType | null | undefined;
}

export const BulletsCell: React.FC<BulletsCellProp> = ({
  bulletRating,
  bulletType
}) => {
  const backgroundImage = cl.url(
    bulletType === "stud" ? "bullet_stud_zbwuf0" : "bullet_draw_x8ylwh",
    {
      quality: "auto:eco"
    }
  );
  return (
    <span
      className="inline-block h-8 w-8 bg-contain bg-no-repeat bg-center text-center text-black font-card font-bold text-lg leading-loose"
      style={{
        backgroundImage: `url(${backgroundImage})`
      }}
    >
      {bulletRating}
    </span>
  );
};

interface GhostRockCellProps {
  ghostRock: string;
}

export const GhostRockCell: React.FC<GhostRockCellProps> = ({ ghostRock }) => {
  const backgroundImage = cl.url("production_upkeep_th8wdq", {
    quality: "auto:eco"
  });
  return (
    <span
      className="inline-block h-6 w-6 bg-contain bg-no-repeat bg-center text-center text-gray-200 font-card font-bold text-lg tracking-tight"
      style={{
        backgroundImage: `url(${backgroundImage})`
      }}
    >
      {ghostRock}
    </span>
  );
};
