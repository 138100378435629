import React from "react";
import { ICard } from "../types/graphql-global-types";
import CardImage from "./CardImage";

interface CardGridProps {
  cards: ICard[];
  className?: String;
  wrapper?: React.ElementType;
  wrapperProps?: any;
  onCardClick?: (card: ICard) => void;
}

const CardGrid: React.FC<CardGridProps> = ({
  cards,
  className,
  wrapper,
  wrapperProps,
  onCardClick
}) => {
  const gridItems = cards.map((card: ICard, key: number) => {
    const cardImage = (
      <CardImage
        className="rounded-xl cursor-pointer"
        loadedClassName="animate__animated animate__fadeIn"
        card={card}
        alt={card.name}
        onClick={card => onCardClick && onCardClick(card)}
      />
    );

    const Wrapper = wrapper ?? null;

    const content = Wrapper ? (
      <Wrapper {...wrapperProps(card)}>{cardImage}</Wrapper>
    ) : (
      cardImage
    );

    return (
      <div
        key={key}
        className="transition-transform duration-150 ease-in transform rounded-xl lg:hover:scale-150 lg:hover:z-50 lg:hover:bg-dark lg:hover:p-2"
      >
        {content}
      </div>
    );
  });

  return (
    <div className={`grid gap-8 grid-cols-2 ${className}`}>{gridItems}</div>
  );
};

export default CardGrid;
