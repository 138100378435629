import React from "react";
import { Deck } from "../../lib/deck";

interface DeckInfoParams {
  deck: Deck;
}

const DeckInfo: React.FC<DeckInfoParams> = ({ deck }) => {
  return (
    <div className="flex flex-row items-center justify-around font-display text-gray-200 bg-dark rounded-xl py-2">
      <div>
        <span className="font-bold">Cards:</span> {deck.size}/{Deck.MAX_CARDS}
      </div>
      <div>
        <span className="font-bold">Jokers:</span> {deck.jokers.length}/
        {Deck.MAX_JOKERS}
      </div>
      <div>
        <span className="font-bold">Starting Dudes:</span>{" "}
        {deck.startingDudes.length}/{Deck.MAX_STARTING_DUDES}
      </div>
    </div>
  );
};

export default DeckInfo;
