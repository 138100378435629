import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import {
  AllCards,
  AllCardsQuery,
  Card,
  ICard,
  Maybe
} from "../types/graphql-global-types";
import { useQuery } from "@apollo/react-hooks";
import CardGrid from "./CardGrid";
import CardImage from "./CardImage";
import CardTable from "./CardTable";
import { useDoomtownDeck } from "../hooks";
import { Link, useLocation } from "react-router-dom";
import { parse } from "../lib/urlParams";
import { debounce } from "lodash";
import DeckInfo from "./cardTable/DeckInfo";
import Columns from "./cardTable/Columns";
import { buildDeckFromFile, createExportParams } from "../lib/deck";
import FileUploadButton from "./FileUploadButton";

const DeckBuilder: React.FC = () => {
  const { search } = useLocation();
  const [leftPreviewCard, setLeftPreviewCard] = useState<Card | null>(null);
  const [rightPreviewCard, setRightPreviewCard] = useState<Card | null>(null);
  const {
    deck,
    replaceDeck,
    setHome,
    addCard,
    removeCard,
    addStartingDude,
    removeStartingDude
  } = useDoomtownDeck();
  const { loading, error, data } = useQuery<AllCardsQuery>(AllCards);

  const cards = data?.getAllCards;
  const params = parse(search);
  const homeId = params["home"];

  if (homeId && cards && deck.home === null) {
    const homeCard = cards.find(c => c.id === homeId);
    if (homeCard) {
      setHome(homeCard);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [homeId]);

  useEffect(() => {
    if (deck.selectedRowIds.length > 0) {
      setRightPreviewCard(deck.cards[deck.selectedRowIds[0]]);
    }
  }, [setRightPreviewCard, deck]);

  return (
    <Layout>
      {loading && <span>Loading...</span>}
      {error && <span>Error!</span>}

      {cards && !deck.home && (
        <CardGrid
          cards={cards.filter(c => c.__typename === "Outfit")}
          className="lg:grid-cols-4 lg:gap-10 xl:grid-cols-5 xl:gap-12"
          wrapper={Link}
          wrapperProps={(card: ICard) => {
            return {
              to: {
                pathname: "/decks/new",
                search: `home=${card.id}`
              }
            };
          }}
        />
      )}

      <div className="p-4 lg:flex lg:flex-row lg:justify-between lg:space-x-4">
        {/* Left Column */}
        <div className="relative lg:flex-1">
          {leftPreviewCard && (
            <div className="w-full absolute inset-0 bg-dark rounded-xl z-10">
              <CardImage
                wait={0}
                className="sticky w-3/4 mx-auto my-4 rounded-xl"
                style={{ top: "16px" }}
                card={leftPreviewCard}
                alt={leftPreviewCard.name}
              />
            </div>
          )}

          {deck.home && cards && (
            <div className="p-4 flex flex-row justify-between items-end space-x-4">
              <CardImage
                className="w-32 rounded-xl"
                card={deck.home}
                alt={deck.home.name}
                onMouseOver={card => setRightPreviewCard(card)}
                onMouseLeave={card => setRightPreviewCard(null)}
              />

              <FileUploadButton
                onChange={(fileList: Maybe<FileList>) => {
                  if (fileList?.length !== 1) {
                    return;
                  }
                  const file = fileList[0];
                  buildDeckFromFile(file, cards)
                    .then(deck => {
                      replaceDeck(deck);
                    })
                    .catch(() => alert("Could not read file."));
                }}
              />

              <a
                className="block px-2 bg-link rounded-lg text-gray-200 uppercase font-display font-bold shadow-lg border border-gray-800 hover:text-gray-400"
                download={true}
                href={`/decks/export?${createExportParams(deck)}`}
              >
                Export
                <svg
                  className="inline ml-1 mb-1 fill-current w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M352 288.994v127.008H64v-288h96v-64H32c-17.664 0-32 14.336-32 32v352c0 17.696 14.336 32 32 32h352c17.696 0 32-14.304 32-32V288.994h-64z" />
                  <path d="M505.6 131.202l-128-96c-4.8-3.648-11.328-4.224-16.736-1.504A15.997 15.997 0 0 0 352 48.002v48h-48c-97.056 0-176 78.944-176 176 0 7.424 5.12 13.888 12.32 15.584 1.216.288 2.464.416 3.68.416 5.952 0 11.552-3.328 14.304-8.832l3.776-7.52c24.544-49.12 73.888-79.648 128.8-79.648H352v48a16.026 16.026 0 0 0 8.832 14.304c5.408 2.72 11.936 2.144 16.768-1.504l128-96c4.032-3.008 6.4-7.776 6.4-12.8s-2.368-9.792-6.4-12.8z" />
                </svg>
              </a>
            </div>
          )}

          {deck.home && <DeckInfo deck={deck} />}

          {cards && deck.home && (
            <div className="mt-2">
              <CardTable
                enablePagination={false}
                enableFiltering={false}
                columns={[
                  Columns.Id(),
                  Columns.Value(),
                  Columns.Suit(),
                  Columns.Type({
                    startingDudes: deck.startingDudes,
                    onDudeClick: (index: number) => {
                      if (deck.startingDudes.includes(index)) {
                        removeStartingDude(index);
                      } else {
                        addStartingDude(index);
                      }
                    }
                  }),
                  Columns.Name()
                ]}
                cards={deck.cards}
                initialSelectedRowIds={deck.selectedRowIds}
                onDblClickCard={(card: Card, index: number) => {
                  removeCard(index);
                  setRightPreviewCard(null);
                }}
                onSelectCard={debounce(
                  (card: Card) => setRightPreviewCard(card),
                  200
                )}
                onDeSelectCard={(card: Card) => setRightPreviewCard(null)}
                onDelete={(index: number) => {
                  removeCard(index);
                }}
              />
            </div>
          )}
        </div>

        {/* Right Column */}
        {cards && deck.home && (
          <div className="relative mt-16 lg:mt-0 lg:w-2/3">
            {rightPreviewCard && (
              <div className="w-full z-10 absolute inset-0 bg-dark rounded-xl">
                <CardImage
                  wait={0}
                  className="sticky md:w-1/2 xl:w-1/3 mx-auto my-4 rounded-xl"
                  style={{ top: "16px" }}
                  card={rightPreviewCard}
                  alt={rightPreviewCard.name}
                />
              </div>
            )}

            <CardTable
              columns={[
                Columns.Id(),
                Columns.Value(),
                Columns.Suit(),
                Columns.Type({
                  onIconClick: (card: Card) => {
                    addCard(card);
                  }
                }),
                Columns.Name(),
                Columns.Text(),
                Columns.Cost(),
                Columns.Influence(),
                Columns.Control(),
                Columns.Bullets(),
                Columns.Aced(),
                Columns.Cheatin(),
                Columns.HarrowedPower(),
                Columns.KungFuPower(),
                Columns.Job(),
                Columns.Noon(),
                Columns.Reaction(),
                Columns.Shootout(),
                Columns.Production(),
                Columns.Public(),
                Columns.Brothel(),
                Columns.Casino(),
                Columns.Government(),
                Columns.HolyGround(),
                Columns.OutOfTown(),
                Columns.Saloon(),
                Columns.Strike(),
                Columns.Improvement(),
                Columns.FactionName(),
                Columns.Experience(),
                Columns.Upkeep(),
                Columns.Huckster(),
                Columns.Blessed(),
                Columns.MadScientist(),
                Columns.Shaman(),
                Columns.SkillLevel(),
                Columns.KungFu(),
                Columns.Harrowed(),
                Columns.Lycanthrope(),
                Columns.Sidekick(),
                Columns.Gadget(),
                Columns.Weapon(),
                Columns.Horse(),
                Columns.Mystical(),
                Columns.Hex(),
                Columns.Miracle(),
                Columns.Spirit()
              ]}
              hiddenColumns={[
                "text",
                "aced",
                "cheatin",
                "harrowedPower",
                "kungFuPower",
                "job",
                "noon",
                "reaction",
                "shootout",
                "production",
                "public",
                "brothel",
                "casino",
                "government",
                "holyGround",
                "outOfTown",
                "saloon",
                "strike",
                "improvement",
                "factionName",
                "experience",
                "upkeep",
                "huckster",
                "blessed",
                "madScientist",
                "shaman",
                "skillLevel",
                "kungFu",
                "harrowed",
                "lycanthrope",
                "sidekick",
                "gadget",
                "weapon",
                "horse",
                "mystical",
                "hex",
                "miracle",
                "spirit"
              ]}
              cards={cards}
              onEnter={(card: Card) => {
                addCard(card);
                setLeftPreviewCard(null);
              }}
              onSelectCard={debounce(
                (card: Card) => setLeftPreviewCard(card),
                200
              )}
              onDeSelectCard={() => setLeftPreviewCard(null)}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default DeckBuilder;
