import qs from "qs";
import { times, range } from "lodash";
import { Card, Joker, Maybe, Outfit } from "../types/graphql-global-types";
import { isJoker, isOutfit } from "./card";

export class Deck {
  static MAX_STARTING_DUDES = 5;
  static MAX_CARDS = 52;
  static MAX_JOKERS = 2;
  static MAX_EACH_CARD = 4;

  readonly name: string | undefined;
  readonly home: Maybe<Outfit>;
  readonly cards: Array<Card>;
  readonly startingDudes: Array<number>;
  readonly jokers: Array<Joker>;
  readonly selectedRowIds: Array<number>;
  readonly size: number;

  constructor(
    name?: string,
    home: Maybe<Outfit> = null,
    cards: Array<Card> = [],
    startingDudes: Array<number> = [],
    jokers: Array<Joker> = [],
    selectedRowIds: Array<number> = []
  ) {
    this.name = name;
    this.home = home;
    this.cards = cards;
    this.startingDudes = startingDudes;
    this.jokers = jokers;
    this.selectedRowIds = selectedRowIds;
    this.size = this.cards.length - this.jokers.length;
  }

  setName(name: string) {
    return new Deck(
      name,
      this.home,
      [...this.cards],
      [...this.startingDudes],
      [...this.jokers]
    );
  }

  setHome(anOutfit: Outfit) {
    return new Deck(
      this.name,
      anOutfit,
      [...this.cards],
      [...this.startingDudes],
      [...this.jokers]
    );
  }

  addCard(aCard: Outfit): Deck;
  addCard(aCard: Joker): Deck;
  addCard(aCard: Card): Deck {
    let newHome = this.home;
    let newCards = [...this.cards];

    if (isOutfit(aCard)) {
      newHome = aCard;
    } else {
      newCards.push(aCard);
    }

    const newJokers = isJoker(aCard)
      ? [...this.jokers, aCard]
      : [...this.jokers];

    return new Deck(
      this.name,
      newHome,
      newCards,
      [...this.startingDudes],
      newJokers
    );
  }

  removeCard(index: number): Deck {
    const cardToRemove = this.cards[0];
    let newJokers = [...this.jokers];

    if (isJoker(cardToRemove)) {
      const jokerToRemove = this.jokers.findIndex(
        c => c.id === cardToRemove.id
      );
      newJokers.splice(jokerToRemove, 1);
    }

    const newCards = this.cards.filter((card, n) => {
      return n !== index;
    });
    const newStartingDudes = this.startingDudes.filter(n => n !== index);
    let newIndex = index;
    if (newIndex + 1 > newCards.length) {
      newIndex -= 1;
    }
    return new Deck(
      this.name,
      this.home,
      newCards,
      newStartingDudes,
      [...newJokers],
      [newIndex]
    );
  }

  addStartingDude(index: number): Deck {
    array_move(this.cards, index, this.startingDudes.length);
    this.startingDudes.push(this.startingDudes.length);
    return new Deck(
      this.name,
      this.home,
      [...this.cards],
      [...this.startingDudes],
      [...this.jokers]
    );
  }

  removeStartingDude(index: number): Deck {
    array_move(this.cards, index, this.startingDudes.length - 1);
    return new Deck(
      this.name,
      this.home,
      [...this.cards],
      Array.from({ length: this.startingDudes.length - 1 }, (x, i) => i),
      [...this.jokers]
    );
  }
}

export function createExportParams(deck: Deck): string {
  const startingDudesIds = deck.startingDudes.map(
    index => deck.cards[index].id
  );
  const otherCardsIds = deck.cards
    .filter((card, index) => !deck.startingDudes.includes(index))
    .map(card => card.id);

  return qs.stringify(
    {
      name: deck?.home?.name,
      homeId: deck?.home?.id,
      startingDudesIds,
      otherCardsIds
    },
    { arrayFormat: "comma" }
  );
}

export function buildDeckFromFile(file: File, cards: Array<Card>): Promise<Deck> {
  const parser = new DOMParser();

  return file.text().then((fileContents) => {
    const xmlDoc = parser.parseFromString(fileContents, "text/xml");

    let homeCard: Card | undefined;
    let deckCards: Array<Card> = [];
    let deckJokers: Array<Joker> = [];
    let numberOfStartingDudes = 0;

    const homeCardElement = xmlDoc.querySelector(
      'deck > section[name="Outfit"] > card'
    );
    if (homeCardElement) {
      homeCard = cards?.find(
        card => card.uuid === homeCardElement.getAttribute("id")
      );
    }

    const startingDudeElements = xmlDoc.querySelectorAll(
      'deck > section[name="Starting Cards"] > card'
    );
    const deckElements = xmlDoc.querySelectorAll(
      'deck > section[name="Deck"] > card'
    );

    startingDudeElements.forEach(sde => {
      const card = cards.find(card => card.uuid === sde.getAttribute("id"));
      const qnt = parseInt(sde.getAttribute("qty") ?? "1");
      numberOfStartingDudes += qnt;
      if (card && qnt) {
        times(qnt, () => deckCards.push(card));
      }
    });

    deckElements.forEach(sde => {
      const card = cards.find(card => card.uuid === sde.getAttribute("id"));
      const qnt = parseInt(sde.getAttribute("qty") ?? "1");
      if (card && qnt) {
        if (isJoker(card)) {
          times(qnt, () => deckJokers.push(card));
        }
        times(qnt, () => deckCards.push(card));
      }
    });

    return new Deck(file.name, homeCard, deckCards, range(0, numberOfStartingDudes), deckJokers);
  });
}

function array_move(arr: Array<any>, old_index: number, new_index: number) {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}
