import {
  Action,
  Card,
  CardType,
  Deed,
  Dude,
  Event,
  Good,
  Joker,
  Maybe,
  Outfit,
  Spell
} from "../types/graphql-global-types";

export type CardTypeStrings = keyof typeof CardType;

export function getName(card: Card): string {
  let name = card.name;
  const experience = (card as Dude).experience;

  if (!experience) {
    return name;
  }

  if (experience === 1) {
    name += ` - E`;
  } else if (experience > 1) {
    name += ` - E${(card as Dude).experience}`;
  }
  return name;
}

export function getCardValue(n: number): string {
  switch (n) {
    case 1:
      return "A";
    case 11:
      return "J";
    case 12:
      return "Q";
    case 13:
      return "K";
    case null:
      return "";
    default:
      return n.toString();
  }
}

const factionNameToImg: { [key: string]: string } = {
  Agency: "fs_the_agency_cqallh",
  Blackjacks: "fs_blackjacks_yw3pa2",
  Collegium: "fs_collegium_duigbe",
  Flock: "fs_the_flock_pjugqo",
  "Law Dogs": "fs_law_dogs_e8dovp",
  "Lost Angels": "fs_lost_angels_hr07sw",
  "Maze Rats": "fs_maze_rats_gj3gnd",
  Sioux: "fs_sioux_union_yqkvnf",
  Sweetrock: "fs_sweetrock_kml8iu",
  "Texas Rangers": "fs_texas_rangers_pjcxdr",
  Whateleys: "fs_whateleys_feywyq"
};

export function getFactionImg(card: Card): Maybe<string> {
  if (isDude(card) || isOutfit(card)) {
    const factionName = card?.faction?.name;
    if (factionName && factionNameToImg.hasOwnProperty(factionName)) {
      return factionNameToImg[factionName];
    }
  }
  return null;
}

export function isAction(card: Card): card is Action {
  return card.__typename === "Action";
}

export function isDeed(card: Card): card is Deed {
  return card.__typename === "Deed";
}

export function isDude(card: Card): card is Dude {
  return card.__typename === "Dude";
}

export function isEvent(card: Card): card is Event {
  return card.__typename === "Event";
}

export function isGood(card: Card): card is Good {
  return card.__typename === "Good";
}

export function isJoker(card: Card): card is Joker {
  return card.__typename === "Joker";
}

export function isOutfit(card: Card): card is Outfit {
  return card.__typename === "Outfit";
}

export function isSpell(card: Card): card is Spell {
  return card.__typename === "Spell";
}
