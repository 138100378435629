import React from "react";

interface SkinnyPaginationParams {
  firstPageDisabled: boolean;
  onFirstPageClick: () => void;
  previousPageDisabled: boolean;
  onPreviousPageClick: () => void;
  nextPageDisabled: boolean;
  onNextPageClick: () => void;
  lastPageDisabled: boolean;
  onLastPageClick: () => void;
  pageNumber: number;
  totalPages: number;
}

const SkinnyPagination: React.FC<Partial<SkinnyPaginationParams>> = ({
  firstPageDisabled = false,
  onFirstPageClick = () => {},
  previousPageDisabled = false,
  onPreviousPageClick = () => {},
  nextPageDisabled = false,
  onNextPageClick = () => {},
  lastPageDisabled = false,
  onLastPageClick = () => {},
  pageNumber = 1,
  totalPages = 1
}) => {
  return (
    <div className="mt-2 flex flex-row justify-between content-center rounded-xl">
      <div>
        <PossibleLink
          disabled={firstPageDisabled}
          onClick={() => onFirstPageClick()}
          activeClasses="px-3 mr-2 shadow border border-black rounded-lg bg-link hover:opacity-100 opacity-75 text-gray-300 cursor-pointer text-xl uppercase font-display font-extrabold select-none"
          disabledClasses="px-3 mr-2 shadow border border-black rounded-lg bg-link-disabled opacity-75 text-gray-100 cursor-auto text-xl uppercase font-display font-extrabold select-none"
        >
          &lt;&lt;
        </PossibleLink>
        <PossibleLink
          disabled={previousPageDisabled}
          onClick={() => onPreviousPageClick()}
          activeClasses="px-8 shadow border border-black rounded-lg bg-link hover:opacity-100 opacity-75 text-gray-300 cursor-pointer text-xl uppercase font-display font-extrabold select-none"
          disabledClasses="px-8 shadow border border-black rounded-lg bg-link-disabled opacity-75 text-gray-100 cursor-auto text-xl uppercase font-display font-extrabold select-none"
        >
          &lt;
        </PossibleLink>
      </div>

      <h2 className="text-lg text-center lg:text-right font-semibold shadow-lg font-display text-gray-100 hidden md:block">
        Page {pageNumber} of {totalPages}
      </h2>

      <div>
        <PossibleLink
          disabled={nextPageDisabled}
          onClick={() => onNextPageClick()}
          activeClasses="px-8 shadow border border-black rounded-lg bg-link hover:opacity-100 opacity-75 text-gray-300 cursor-pointer text-xl uppercase font-display font-extrabold select-none"
          disabledClasses="px-8 shadow border border-black rounded-lg bg-link-disabled opacity-75 text-gray-100 cursor-auto text-xl uppercase font-display font-extrabold select-none"
        >
          &gt;
        </PossibleLink>
        <PossibleLink
          disabled={lastPageDisabled}
          onClick={() => onLastPageClick()}
          activeClasses="px-3 ml-2 shadow border border-black rounded-lg bg-link hover:opacity-100 opacity-75 text-gray-300 cursor-pointer text-xl uppercase font-display font-extrabold select-none"
          disabledClasses="px-3 ml-2 shadow border border-black rounded-lg bg-link-disabled opacity-75 text-gray-100 cursor-auto text-xl uppercase font-display font-extrabold select-none"
        >
          &gt;&gt;
        </PossibleLink>
      </div>
    </div>
  );
};

const PossibleLink: React.FC<{
  disabled: boolean;
  activeClasses: string;
  disabledClasses: string;
  onClick: () => void;
}> = ({ disabled, activeClasses, disabledClasses, onClick, children }) => {
  if (disabled) {
    return <span className={disabledClasses}>{children}</span>;
  } else {
    return (
      <span onClick={onClick} className={activeClasses}>
        {children}
      </span>
    );
  }
};

export default SkinnyPagination;
